import moment from "moment";

export const calculateVacationImpactIn = (
  r: any,
  VacationDates: any[],
  MINUTES_IN_DAY: any,
  shiftVacationPerHours: number,
  rest: any,
  totalMinutesInDay: number,
  workedDay: boolean
) => {
  VacationDates.forEach((v) => {
    const start = moment(v.start).startOf("day");
    const end = moment(v.end).endOf("day");

    if (moment(r).isBetween(start, end, undefined, "[]")) {
      let currentDateFormatted = moment(r).format("DD.MM.YYYY");

      if (rest?.date === currentDateFormatted && currentDateFormatted == end.format("DD.MM.YYYY")) {
        const restedMin = rest?.min;

        const vacatedMin = MINUTES_IN_DAY + restedMin >= totalMinutesInDay ? 0 : MINUTES_IN_DAY + restedMin;

        rest = {
          min: MINUTES_IN_DAY + restedMin >= totalMinutesInDay ? MINUTES_IN_DAY + restedMin - totalMinutesInDay : 0,
          date: moment(r, "DD.MM.YYYY").add(1, "days").format("DD.MM.YYYY").toString(),
        };
        workedDay = MINUTES_IN_DAY + restedMin >= totalMinutesInDay ? false : true;

        MINUTES_IN_DAY = vacatedMin;
      } else {
        const vacationALLMinPeriod = v.duration * shiftVacationPerHours * 60;
        const vacatedMin =
          MINUTES_IN_DAY + vacationALLMinPeriod >= totalMinutesInDay ? 0 : MINUTES_IN_DAY + vacationALLMinPeriod;
        rest = {
          min:
            MINUTES_IN_DAY + vacationALLMinPeriod >= totalMinutesInDay
              ? MINUTES_IN_DAY + vacationALLMinPeriod - totalMinutesInDay
              : 0,
          date: moment(r, "DD.MM.YYYY").add(1, "days").format("DD.MM.YYYY").toString(),
        };
        workedDay = vacatedMin == 0 ? false : true;

        MINUTES_IN_DAY = vacatedMin;
      }
    }
  });
  return { rest, MINUTES_IN_DAY, workedDay }; // Return the updated rest object
};

export const calculateVacationImpactOut = (
  r: any,
  VacationDates: any[],
  MINUTES_IN_DAY: any,
  shiftVacationPerHours: number,
  rest: any,
  totalMinutesInDay: number,
  day_log_in_min: number,
  workedDay: boolean
) => {
  VacationDates.forEach((v) => {
    const start = moment(v.start).startOf("day");
    const end = moment(v.end).endOf("day");

    if (moment(r).isBetween(start, end, undefined, "[]")) {
      let currentDateFormatted = moment(r).format("DD.MM.YYYY");

      if (rest?.date === currentDateFormatted && currentDateFormatted == end.format("DD.MM.YYYY")) {
        const restedMin = rest?.min;
        const vacatedMin = day_log_in_min + restedMin >= totalMinutesInDay ? 0 : MINUTES_IN_DAY;

        rest = {
          min: day_log_in_min + restedMin >= totalMinutesInDay ? day_log_in_min + restedMin - totalMinutesInDay : 0,
          date: moment(r, "DD.MM.YYYY").add(1, "days").format("DD.MM.YYYY").toString(),
        };
        workedDay = day_log_in_min + restedMin >= totalMinutesInDay ? false : true;

        MINUTES_IN_DAY = vacatedMin;
      } else {
        const vacationALLMinPeriod = v.duration * shiftVacationPerHours * 60;
        const vacatedMin = day_log_in_min + vacationALLMinPeriod >= totalMinutesInDay ? 0 : MINUTES_IN_DAY;

        rest = {
          min:
            day_log_in_min + vacationALLMinPeriod >= totalMinutesInDay
              ? day_log_in_min + vacationALLMinPeriod - totalMinutesInDay
              : 0,
          date: moment(r, "DD.MM.YYYY").add(1, "days").format("DD.MM.YYYY").toString(),
        };
        workedDay = day_log_in_min + vacationALLMinPeriod >= totalMinutesInDay ? false : true;

        MINUTES_IN_DAY = vacatedMin;
      }
    }
  });
  return { rest, MINUTES_IN_DAY, workedDay }; // Return the updated rest object
};

export const calculateVacatedHoursImpact = (
  r: any,
  VacationDates: any[],
  MINUTES_IN_DAY: any,
  shiftVacationPerHours: number,
  rest: any,
  totalMinutesInDay: number,
  day_log_in_min: number,
  vacationType: string
) => {
  VacationDates.filter((v) => {
    if (vacationType == "Other Leave") {
      return v.type !== "Sick Leave" && v.type !== "Annual Leave" && v.type !== "Unpaid Leave";
    } else {
      return v.type == vacationType;
    }
  }).forEach((v) => {
    const start = moment(v.start).startOf("day");
    const end = moment(v.end).endOf("day");

    if (moment(r).isBetween(start, end, undefined, "[]")) {
      let currentDateFormatted = moment(r).format("DD.MM.YYYY");

      if (rest?.date === currentDateFormatted && currentDateFormatted == end.format("DD.MM.YYYY")) {
        const restedMin = rest?.min;
        const vacatedMin =
          day_log_in_min + restedMin > totalMinutesInDay
            ? restedMin > totalMinutesInDay
              ? totalMinutesInDay
              : totalMinutesInDay - (day_log_in_min + restedMin)
            : restedMin;

        rest = {
          min: day_log_in_min + restedMin > totalMinutesInDay ? day_log_in_min + restedMin - totalMinutesInDay : 0,
          date: moment(r, "DD.MM.YYYY").add(1, "days").format("DD.MM.YYYY").toString(),
        };
        MINUTES_IN_DAY = vacatedMin;
      } else {
        const vacationALLMinPeriod = v.duration * shiftVacationPerHours * 60;
        const vacatedMin =
          day_log_in_min + vacationALLMinPeriod > totalMinutesInDay
            ? totalMinutesInDay - day_log_in_min
            : vacationALLMinPeriod;

        rest = {
          min:
            day_log_in_min + vacationALLMinPeriod > totalMinutesInDay
              ? day_log_in_min + vacationALLMinPeriod - totalMinutesInDay
              : 0,
          date: moment(r, "DD.MM.YYYY").add(1, "days").format("DD.MM.YYYY").toString(),
        };
        MINUTES_IN_DAY = vacatedMin;
      }
    }
  });
  return { rest, MINUTES_IN_DAY }; // Return the updated rest object
};
