import React, { useEffect, useState } from "react";
import { Spin, Button, Select, DatePicker } from "antd";
const { Option } = Select;
import { sortBy } from "lodash";
import moment from "moment";
import { formatMMM } from "../../db/dateFormat";
import AddNewVacation from "./VacationsModules/AddNewVacation";
import VacationSummary from "./VacationSummary";
import { vacation } from "../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  fetchVacationByUserId,
  fetchVacationsByOfficeByDate,
  subscribeToVacations,
} from "../../features/vacations/vacationsSlice";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { OFFICES_ID_NAME } from "../../global/officeList";
import { setFilterValue } from "../../features/filters/filtersSlice";
import { subscribeToUsers } from "../../features/users/usersSlice";
const { MonthPicker } = DatePicker;

export default function VacationList() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: vacationsData, status: s1, error } = useAppSelector((state) => state.vacations);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [modalState, setModalState] = useState(false);
  const [state, setState] = useState("showAll");
  const [manager, setManager] = useState(false);
  const [month, setMonth] = useState(moment());
  const [employeeUid, setEmployeeUid] = useState("Select an employee");
  const [vacationStatus, setVacationStatus] = useState("");
  const uid = currentUser.id as string;
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const hasAccess = currentUser.fullControlOffices?.length != 0;
  const isAdmin = currentUser.adminOffices?.length != 0;
  const isHR = currentUser.HROffices?.length != 0 || currentUser.HRProjectsManagersOffices?.length != 0;

  const isCEO = currentUser?.CEO;

  useEffect(() => {
    dispatch(
      fetchVacationsByOfficeByDate({
        officeId: officeId == "Select an office..." ? currentUser?.office : officeId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
        byField: "vacationStartOn",
      })
    );
    // const unsubscribeToUsers = subscribeToUsers(dispatch);

    const unsubscribe = subscribeToVacations(dispatch);
    return () => {
      // unsubscribeToUsers();
      unsubscribe();
    };
  }, [dispatch, officeId, month]);

  if (hasAccess || isCEO) {
    const vacationListSN = vacationsData.filter(
      (e) =>
        currentUser?.fullControlOffices?.includes(e?.office) || e?.employeeUID == uid || e?.managerUID == uid || isCEO
    );

    const HRSig = vacationListSN?.filter((d: any) => {
      return d.HRSignatureDate == "" && d.managerSignatureDate != "";
    });
    const AdminSig = vacationListSN?.filter((d: any) => {
      return d.adminSignatureDate == "" && d.HRSignatureDate != "";
    });
    const ManagerSig = vacationListSN?.filter((d: any) => {
      return d.managerUID == uid;
    });
    const mySig = vacationListSN?.filter((d: any) => {
      return d.employeeUID == uid;
    });
    const sortedList = sortBy(vacationListSN, (item) => item.requestedAt).reverse();

    const sortedListAdmin = sortBy(AdminSig, (item) => item.requestedAt).reverse();
    const sortedListHR = sortBy(HRSig, (item) => item.requestedAt).reverse();
    const sortedListManager = sortBy(ManagerSig, (item) => item.requestedAt).reverse();
    const sortedListMaine = sortBy(mySig, (item) => item.requestedAt).reverse();

    let listView: any;
    switch (state) {
      case "showAll":
        listView = sortedList;
        break;
      case "admin":
        listView = sortedListAdmin;
        break;
      case "HR":
        listView = sortedListHR;
        break;
      case "manager":
        listView = sortedListManager;
        break;
      case "maine":
        listView = sortedListMaine;
        break;
      default:
        listView = sortedList;
        break;
    }

    if (s1 == "loading")
      return (
        <div>
          <Spin size="small" />
        </div>
      );
    return (
      <div>
        {modalState ? <AddNewVacation modalState={modalState} setModalState={setModalState} /> : null}

        <Button
          type="default"
          style={{ margin: "1%", width: "98%", alignItems: "center", background: "#ffa500", color: "white" }}
          onClick={() => {
            setState("leavesToday");
            setEmployeeUid("Select an employee");
            setVacationStatus("");
          }}
        >
          Today's Leaves{" "}
        </Button>
        <br />
        <MonthPicker
          size="default"
          style={{ margin: "1%", width: "98%" }}
          placeholder="Select month"
          value={month}
          format={"MMMM-YYYY"}
          onChange={(e: any) => {
            setMonth(moment(e));
          }}
        />
        <br />
        <Button
          type="primary"
          style={{ margin: "1%", width: "48%" }}
          onClick={() => {
            setState("maine");
          }}
        >
          My Vacations{" "}
        </Button>
        {isAdmin ? (
          <Button
            type="primary"
            style={{ margin: "1%", width: "48%" }}
            onClick={() => {
              setState("admin");
              setEmployeeUid("Select an employee");
              setVacationStatus("");
            }}
          >
            Admin
          </Button>
        ) : null}
        {isHR ? (
          <Button
            type="primary"
            style={{ margin: "1%", width: "48%" }}
            onClick={() => {
              setState("HR");
              setEmployeeUid("Select an employee");
              setVacationStatus("");
            }}
          >
            HR
          </Button>
        ) : null}
        <Button
          type="primary"
          style={{ margin: "1%", width: "48%" }}
          onClick={() => {
            setState("manager");
            setEmployeeUid("Select an employee");
            setVacationStatus("");
          }}
        >
          My Signatures
        </Button>
        <br />
        <Button
          type="default"
          style={{ margin: "1%", width: "98%", alignItems: "center" }}
          onClick={() => {
            setState("showAll");
            setEmployeeUid("Select an employee");
            setVacationStatus("");
          }}
        >
          Show All{" "}
        </Button>
        <br />
        {state == "showAll" || state == "manager" || state == "leavesToday" ? (
          <div>
            <Button
              type="danger"
              style={{ margin: "1%" }}
              onClick={() => {
                setVacationStatus("Requested");
              }}
            >
              Requested
            </Button>
            <Button
              type="primary"
              style={{ margin: "1%" }}
              onClick={() => {
                setVacationStatus("Pending");
              }}
            >
              Pending{" "}
            </Button>
            <Button
              style={{ margin: "1%", background: "#77c556", color: "white" }}
              onClick={() => {
                setVacationStatus("Accepted");
              }}
            >
              Accepted{" "}
            </Button>
          </div>
        ) : null}
        {state != "maine" ? (
          <div>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              size="default"
              placeholder="Search for Office!"
              style={{ width: "100%", paddingBottom: "1%" }}
              showSearch
              value={officeId}
              onChange={(e) => {
                setOfficeId(e);
                dispatch(setFilterValue({ key: "officeId", value: e }));
              }}
            >
              {OFFICES_ID_NAME.map((d) => {
                return <Option value={d.officeId}>{d.officeName}</Option>;
              })}
            </Select>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              size="default"
              placeholder="Search for Name!"
              style={{ width: "100%", paddingBottom: "1%" }}
              showSearch
              value={employeeUid}
              onChange={(e: string) => {
                setEmployeeUid(e);
              }}
            >
              {USERS_ID_FULL_NAME?.map((d: any) => {
                return <Option value={d.uid}>{d.fullName}</Option>;
              })}
            </Select>
          </div>
        ) : null}
        <br />

        {listView
          .filter((v: any) => {
            if (employeeUid == "Select an employee") {
              return v;
            } else {
              return v?.employeeUID == employeeUid;
            }
          })
          .filter((v: any) => {
            if (vacationStatus == "") {
              return v;
            } else {
              return v?.status == vacationStatus;
            }
          })
          .filter((v: any) => {
            if (state == "leavesToday") {
              const vacationStartOn = moment(v?.vacationStartOn).startOf("day");
              const vacationEndOn = moment(v?.vacationEndOn).endOf("day");
              return moment().isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
            } else {
              return v;
            }
          })
          .map((doc: any) => {
            const data = doc as vacation;
            const fullName = globalUsersData[data.employeeUID]?.fullName as string;
            return (
              <VacationSummary
                vacationType={data?.vacationType}
                key={doc.id}
                id={doc.id}
                employeeUID={fullName}
                vacationStartOn={moment(data?.vacationStartOn).format(formatMMM)}
                vacationEndOn={moment(data?.vacationEndOn as any).format(formatMMM)}
                status={doc.status}
              />
            );
          })}
        <Button
          disabled={currentUser.vacationAbility == undefined || currentUser.vacationAbility ? false : true}
          type="primary"
          shape="circle"
          size="large"
          onClick={() => {
            setModalState(true);
          }}
        >
          +
        </Button>
      </div>
    );
  } else {
    const vacationListSN = vacationsData.filter((d: any) => {
      if (manager) {
        return d.managerUID == uid;
      } else {
        return d.employeeUID == uid;
      }
    });

    const sortedList = sortBy(vacationListSN, (item) => item.requestedAt).reverse();

    if (s1 == "loading")
      return (
        <div>
          <Spin size="small" />
        </div>
      );
    return (
      <div>
        <AddNewVacation modalState={modalState} setModalState={setModalState} />
        <MonthPicker
          size="large"
          style={{ margin: "1%", width: "98%" }}
          placeholder="Select month"
          value={month}
          format={"MMMM-YYYY"}
          onChange={(e: any) => {
            setMonth(moment(e));
          }}
        />
        <Button
          type="primary"
          style={{ margin: "1%", width: "98%" }}
          onClick={() => {
            setManager(!manager);
            setEmployeeUid("Select an employee");
          }}
        >
          {manager ? "Go to My Vacations" : "Go to Manager Signatures"}
        </Button>
        <br />
        {manager ? (
          <div>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              size="default"
              placeholder="Search for Office!"
              style={{ width: "100%", paddingBottom: "1%" }}
              showSearch
              value={officeId}
              onChange={(e) => {
                setOfficeId(e);
                dispatch(setFilterValue({ key: "officeId", value: e }));
              }}
            >
              {OFFICES_ID_NAME.map((d) => {
                return <Option value={d.officeId}>{d.officeName}</Option>;
              })}
            </Select>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              size="default"
              placeholder="Search for Name!"
              style={{ width: "100%", paddingBottom: "1%" }}
              showSearch
              value={employeeUid}
              onChange={(e: string) => {
                setEmployeeUid(e);
              }}
            >
              {USERS_ID_FULL_NAME?.map((d: any) => {
                return <Option value={d.uid}>{d.fullName}</Option>;
              })}
            </Select>
          </div>
        ) : null}
        {sortedList
          .filter((v: any) => {
            if (employeeUid == "Select an employee") {
              return v;
            } else {
              return v?.employeeUID == employeeUid;
            }
          })
          .map((doc: any) => {
            const data = doc as vacation;
            const fullName = globalUsersData[data?.employeeUID ?? ""]?.fullName as string;
            return (
              <VacationSummary
                vacationType={data?.vacationType}
                key={doc.id}
                id={doc.id}
                employeeUID={fullName}
                vacationStartOn={moment(data?.vacationStartOn).format(formatMMM)}
                vacationEndOn={moment(data?.vacationEndOn as any).format(formatMMM)}
                status={doc.status}
              />
            );
          })}
        <Button
          disabled={currentUser.vacationAbility == undefined || currentUser.vacationAbility ? false : true}
          type="primary"
          shape="circle"
          size="large"
          onClick={() => {
            setModalState(true);
          }}
        >
          +
        </Button>
      </div>
    );
  }
}
