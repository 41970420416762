import React, { useState, useRef, useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Select, DatePicker, Checkbox, Icon, InputNumber } from "antd";
const { Option } = Select;
import { sortBy, sumBy, uniq, uniqBy } from "lodash";
import moment from "moment";
const { MonthPicker } = DatePicker;
import DevTable from "../../helpers/devex-table";
import { currentSalary } from "../../db/currentSalary";
import { currentP } from "../../db/currentPosition";
import { globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import PaySlipToPrint from "./paySlipToPrint";
import { globalUsersData } from "../../global/usersList";
import { globalProjectsData } from "../../global/projectList";
import _ from "lodash";
import { currentContractPercentage } from "../../db/currentContractPercentage";
import { currentPaymentType } from "../../db/currentPaymentTypes";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchAllocationsByOfficeIdAndDate } from "../../features/allocations/allocationsSlice";
import { setFilterValue } from "../../features/filters/filtersSlice";
import { globalBudgetLinesData } from "../../global/budgetLineList";
import { fetchVacationsByOfficeByDate } from "../../features/vacations/vacationsSlice";
import { fetchDeductionsByOfficeAndMonth } from "../../features/deductions/deductionsSlice";
import { fetchAccessByOfficeAndOperationDate } from "../../features/access/accessSlice";
import { fetchDelaysByOfficeAndMonth } from "../../features/delays/delaysSlice";
import { fetchAllAccountants } from "../../features/accountants/accountantsSlice";

export default function PayslipByOffice() {
  const print2CbRef = useRef<() => void>();
  const print3CbRef = useRef<() => void>();
  const { filterValues } = useAppSelector((state) => state.filters);
  const {
    data: accountantsData,
    status: accountantsStatus,
    error: accountantsError,
  } = useAppSelector((store) => store.accountants);
  const {
    data: vacationsData,
    status: vacationsStatus,
    error: vacationsError,
  } = useAppSelector((state) => state.vacations);
  const {
    data: allocationsData,
    status: allocationsStatus,
    error: allocationsError,
  } = useAppSelector((store) => store.allocations);
  const { data: delaysData, status: delaysStatus, error: delaysError } = useAppSelector((store) => store.delays);
  const { data: accessData, status: accessStatus, error: accessError } = useAppSelector((store) => store.access);
  const {
    data: deductionsData,
    status: deductionsStatus,
    error: deductionsError,
  } = useAppSelector((store) => store.deductions);

  const dispatch = useAppDispatch();

  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");
  const [month, setMonth] = useState(moment().startOf("month").startOf("day"));
  const [filteredPosition, setFilteredPosition] = useState([] as string[]);
  const [fingerPrintActive, setFingerPrintActive] = useState(false);
  const [projectId, setProjectId] = useState(filterValues["officeId"] ?? "Select a project..");
  const [accountantId, setAccountantId] = useState("Select an accountant..");
  const [paymentDate, setPaymentDate] = useState(moment());
  const [row, setRow] = useState({} as any);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [paymentOffice, setPaymentOffice] = useState();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [defaultColumnWidths] = useState([
    { columnName: "pay_no", width: 150 },
    { columnName: "id", width: 150 },
    { columnName: "resourceNO", width: 150 },
    { columnName: "firstName", width: 150 },
    { columnName: "lastName", width: 150 },
    { columnName: "position", width: 150 },
    { columnName: "workingDayInMonth", width: 150 },
    { columnName: "deduction", width: 150 },
    { columnName: "leaveWithoutPay", width: 250 },
    { columnName: "absentDays", width: 150 },
    { columnName: "daysWorked", width: 150 },
    { columnName: "monthlySalary", width: 250 },
    { columnName: "numberOfHoursLate", width: 250 },
    { columnName: "hoursLateDeduction", width: 250 },
    { columnName: "totalSalaryForCurrentMonth", width: 250 },
    { columnName: "difference", width: 150 },
    { columnName: "donor", width: 150 },
    { columnName: "project", width: 150 },
    { columnName: "budgetLineId", width: 150 },
    { columnName: "percentage", width: 150 },
    { columnName: "allocatedSalary", width: 200 },
    { columnName: "print", width: 200 },
    { columnName: "idaContractPercentage", width: 200 },
    { columnName: "contractSalary", width: 200 },
  ]);

  useEffect(() => {
    dispatch(fetchAllAccountants());
    dispatch(
      fetchVacationsByOfficeByDate({
        officeId: officeId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
        byField: "vacationStartOn",
      })
    );
    dispatch(
      fetchAllocationsByOfficeIdAndDate({
        officeId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
      })
    );
    dispatch(
      fetchAccessByOfficeAndOperationDate({
        officeId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
      })
    );
    dispatch(fetchDelaysByOfficeAndMonth({ officeId: officeId, month: month.format("MMM.YYYY").toString() }));
    dispatch(fetchDeductionsByOfficeAndMonth({ officeId, month: month.format("MM-YYYY").toString() }));
  }, [dispatch, month, officeId]);

  const printPayslip = useCallback(() => {
    setTimeout(() => {
      print2CbRef.current();
    }, 1500);
  }, []);
  const printCb = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .pagebreak {
          page-break-before:always;
        }
        .tg {
          border-collapse: collapse;
          border-spacing: 0;
        }
        .tg td {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg th {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg .tg-jqmv {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-z4i2 {
          border-color: #ffffff;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-w7c0 {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-zv4m {
          border-color: #ffffff;
          text-align: left;
          vertical-align: top;
        }
        .tg .tg-pgku {
          border-color: #ffffff;
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-9ksr {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u6id {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-u6id1 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-fmc2 {
          border-color: #ffffff;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-lh7k {
          border-color: #ffffff;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-sl9l {
          border-color: #ffffff;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2urc {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-top-width: 3px;
          border-right-width: 3px;
        
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ui32 {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd0 {
          border-color: black;
          border-top-width: 3px;
        
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ai0l {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: top;
        }
        .tg .tg-f3so {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-f3sox {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-9o1m {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2776 {
          border-color: black;
          border-width: 1px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776x {
          border: none;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776xy {
          border-color: black;
          border-width: 0;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27762 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27760 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-277601 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-u9r8 {
          border-color: black;
          border-top-width: 3px;
        
          border-width: 1px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r80 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r81 {
          border-color: black;
          border-width: 1px;
          border-right-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r82 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r83 {
          border-color: black;
          border-top-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-mlkz {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-oedm {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-0326 {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: right;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-ttyt {
          border-color: #ffffff;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-ttytx {
          border-color: black;
          border-width:0 ;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-8srf {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        
        
        </style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`multiplePage`);
  }, []);
  print2CbRef.current = printCb;

  const printPayslipPerRow = useCallback(() => {
    setTimeout(() => {
      print3CbRef.current();
    }, 1500);
  }, []);
  const printPerRow = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .pagebreak {
          page-break-before:always;
        }
        .tg {
          border-collapse: collapse;
          border-spacing: 0;
        }
        .tg td {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg th {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg .tg-jqmv {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-z4i2 {
          border-color: #ffffff;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-w7c0 {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-zv4m {
          border-color: #ffffff;
          text-align: left;
          vertical-align: top;
        }
        .tg .tg-pgku {
          border-color: #ffffff;
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-9ksr {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u6id {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-u6id1 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-fmc2 {
          border-color: #ffffff;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-lh7k {
          border-color: #ffffff;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-sl9l {
          border-color: #ffffff;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2urc {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-top-width: 3px;
          border-right-width: 3px;
        
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ui32 {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd0 {
          border-color: black;
          border-top-width: 3px;
        
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ai0l {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: top;
        }
        .tg .tg-f3so {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-f3sox {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-9o1m {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2776 {
          border-color: black;
          border-width: 1px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776x {
          border: none;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776xy {
          border-color: black;
          border-width: 0;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27762 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27760 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-277601 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-u9r8 {
          border-color: black;
          border-top-width: 3px;
        
          border-width: 1px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r80 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r81 {
          border-color: black;
          border-width: 1px;
          border-right-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r82 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r83 {
          border-color: black;
          border-top-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-mlkz {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-oedm {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-0326 {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: right;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-ttyt {
          border-color: #ffffff;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-ttytx {
          border-color: black;
          border-width:0 ;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-8srf {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        
        
        </style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`perRow`);
  }, []);
  print3CbRef.current = printPerRow;

  if (
    vacationsStatus == "loading" ||
    allocationsStatus == "loading" ||
    delaysStatus == "loading" ||
    accessStatus == "loading" ||
    deductionsStatus == "loading" ||
    accountantsStatus == "loading"
  )
    return <Spin />;

  const accountants = accountantsData;
  const delays = delaysData
    .map((d) => {
      return { ...d, id: d.id, uid: d.userUID, min: d.min, status: d.status };
    })
    .filter((d) => d.status != "disabled");

  const VacationDates = vacationsData
    .filter((vacation) => vacation.status != "Rejected" && vacation.status != "Canceled")
    .map((d) => {
      return {
        ...d,
        employeeUID: d.employeeUID,
        start: d.vacationStartOn,
        end: d.vacationEndOn,
        duration: d.vacationPeriodDays,
        type: d.vacationType,
        halfDay: d.halfDay,
        day: d.halfDay ? 0.5 : 1,
      };
    });
  function daysInMonth(m: any) {
    var count = moment().year(m.year()).month(m.month()).daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(moment().year(m.year()).month(m.month()).date(i));
    }
    return days;
  }
  var days = daysInMonth(month);
  const deductionData = deductionsData;
  const projectList = Object.values(globalProjectsData).map((p) => {
    return { id: p.id, projectCode: p?.projectCode, donor: p?.donor };
  });

  const userAllocation = allocationsData
    .filter((a) => globalUsersData[a.uid])
    .map((a: any) => {
      const user = globalUsersData[a.uid];
      return { ...a, ...user, id: a.id, userId: user.id };
    });
  const access = accessData;

  const absentDaysArr = userAllocation.map((u: any) => {
    const absentDays = days
      .map((day) => {
        let absent = false;

        const accessInDay = access
          .filter((a) => a.uid == u.userId)
          .filter((a) => {
            return moment(a.date).format("DD-MM-YYYY").toString() == moment(day).format("DD-MM-YYYY").toString();
          }).length;

        const vacationsFiltered = VacationDates.filter((v) => v.employeeUID == u.userId).filter((vacation: any) => {
          const vacationStartOn = moment(vacation?.vacationStartOn).startOf("day");
          const vacationEndOn = moment(vacation?.vacationEndOn).endOf("day");

          return moment(day).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
        }).length;

        if (vacationsFiltered == 0 && accessInDay == 0) {
          absent = true;
        }

        return { absent, day };
      })
      .filter((ab) => ab.absent == true).length;

    return { uid: u.userId, absentDays };
  });

  const data = sortBy(
    userAllocation.map((row: any) => {
      const id = row.id;
      const userId = row.userId;
      const missionId = globalOfficesData[row.office].missionId;
      const position = currentP(userId, month) ?? row.position;

      const allocationStartDate = row.startDate;
      const allocationEndDate = row.endDate;

      const selectedMonth = month;
      const startDate = moment(allocationStartDate);
      const endDate = moment(allocationEndDate);

      const selectedMonthDays = _.range(
        startDate.month() === selectedMonth.month() ? startDate.date() : 1,
        endDate.month() === selectedMonth.month()
          ? endDate.date() + 1
          : moment(startDate).month(selectedMonth.month()).endOf("month").date() + 1
      ).length;

      const monthDays = days.length;
      const workingDayInMonth = selectedMonthDays;

      const deduction = deductionData.find((d) => d.uid == userId)?.percentage ?? 0;

      const unpaidVacationMap = days.map((day) => {
        const unpaidLeaveFiltered = VacationDates.filter(
          (v) => v.employeeUID == userId && v.type == "Unpaid Leave"
        ).filter((vacation: any) => {
          const vacationStartOn = moment(vacation?.vacationStartOn).startOf("day");
          const vacationEndOn = moment(vacation?.vacationEndOn).endOf("day");
          return moment(day).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
        });

        const sum = _.sumBy(unpaidLeaveFiltered, "day");
        return sum;
      });

      const leaveWithoutPay = _.sumBy(unpaidVacationMap);

      const absentDays = (fingerPrintActive ? absentDaysArr.find((ab) => ab.uid == userId)?.absentDays : 0) as number;
      const daysWorked = workingDayInMonth - absentDays * 2 - leaveWithoutPay;

      const monthlySalary = currentSalary(userId, month).salary ?? row.salary;

      const idaContractPercentage =
        currentContractPercentage(userId, month) ?? globalUsersData[userId]?.idaAllocationPercentage;
      const contractSalary = (monthlySalary * idaContractPercentage) / 100;

      const delaysMin = sumBy(
        delays.filter((d) => d.uid == userId),
        (d) => d.min
      );

      const delaysHours = delaysMin >= 120 ? delaysMin / 60 : 0;
      const numberOfHoursLate = delaysHours.toFixed(2);

      const salaryInHour = contractSalary / monthDays / 8;
      const deductionByHour = salaryInHour * (delaysHours * 2);
      const hoursLateDeduction = deductionByHour.toFixed(3);
      ///
      const salaryInDay = contractSalary / monthDays;
      const deductionPercentage = deductionData.find((d) => d.uid == userId)?.percentage ?? 0;
      const salaryAfterDeduction =
        salaryInDay * daysWorked - deductionByHour - (deductionPercentage * contractSalary * row.percentage) / 10000;

      const totalSalaryForCurrentMonth = parseFloat(salaryAfterDeduction.toFixed(2));
      ///
      const differenceNumber = contractSalary - salaryAfterDeduction;
      const difference = parseFloat(differenceNumber.toFixed(2));
      ///
      const donor = globalProjectsData[row.projectId ?? ""]?.donor;
      const project = globalProjectsData[row.projectId ?? ""]?.projectCode;
      const budgetLineId = globalBudgetLinesData[row.budgetLineId ?? ""]?.budgetLine;
      const percentage = row.percentage + " " + "%";
      const allocatedSalaryNumber = (row.percentage * salaryAfterDeduction) / 100;
      const allocatedSalary = parseFloat(allocatedSalaryNumber.toFixed(2));
      const allocations = allocationsData.filter((a) => a.uid == userId);
      const currency = currentSalary(userId, month).currency ?? row?.currency;

      const paymentTypeMethod = currentPaymentType(userId, month) ?? globalUsersData[userId]?.paymentType;

      return {
        ...row,
        position,
        workingDayInMonth,
        deduction,
        leaveWithoutPay,
        absentDays,
        daysWorked,
        monthlySalary,
        currency,
        numberOfHoursLate,
        hoursLateDeduction,
        totalSalaryForCurrentMonth,
        salaryAfterDeduction,
        difference,
        differenceNumber,
        donor,
        project,
        budgetLineId,
        percentage,
        missionId,
        allocatedSalary,
        allocatedSalaryNumber,
        id,
        contractSalary,
        allocations,
        idaContractPercentage,
        paymentTypeMethod,
      };
    }),
    ["firstName", "lastName"]
  ).map((d, index) => {
    return { ...d, index: index + 1 };
  });
  const positions = uniq(data.map((d) => d.position));
  const tableData = data
    .filter((d: any) => {
      if (filteredPosition.length == 0) {
        return true;
      } else {
        if (filteredPosition.includes(d.position)) {
          return true;
        } else {
          return false;
        }
      }
    })
    .filter((d) => {
      if (paymentMethod == "") {
        return true;
      } else {
        return d.paymentTypeMethod == paymentMethod;
      }
    })
    .filter((d) => {
      if (projectId == "Select a project..") {
        return true;
      } else {
        return d.projectId == projectId;
      }
    });

  const columns = [
    {
      name: "pay_no",
      title: "Pay-NO",
      getCellValue: (row: any) => {
        return parseInt(row.pay_no);
      },
    },

    { name: "id", title: "id" },
    { name: "resourceNO", title: "Resource NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    {
      name: "position",
      title: "Position",
    },
    {
      name: "workingDayInMonth",
      title: "Work days in a month",
    },
    {
      name: "deduction",
      title: "Deduction",
    },
    {
      name: "leaveWithoutPay",
      title: "Leave Without Pay",
    },
    {
      name: "absentDays",
      title: "Absent Days",
    },
    {
      name: "daysWorked",
      title: "Days worked",
    },
    {
      name: "monthlySalary",
      title: "Monthly salary ( TL/ USD )",
    },
    {
      name: "idaContractPercentage",
      title: "Contract Percentage",
    },
    {
      name: "contractSalary",
      title: "Contract Salary",
    },
    {
      name: "numberOfHoursLate",
      title: "Number of Hours Late",
    },
    {
      name: "hoursLateDeduction",
      title: "Hours Late Deduction",
    },
    {
      name: "totalSalaryForCurrentMonth",
      title: "Total Salary for current month",
    },
    {
      name: "difference",
      title: "Difference",
    },
    {
      name: "donor",
      title: "Donor",
    },
    {
      name: "project",
      title: "Project",
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
    },
    {
      name: "percentage",
      title: "Percentage",
    },
    {
      name: "allocatedSalary",
      title: "Allocated Salary",
    },
    {
      name: "print",
      title: "Print",
      getCellValue: (r) => {
        return (
          <Button
            onClick={() => {
              setRow(r);
              printPayslipPerRow();
            }}
          >
            Payslip
          </Button>
        );
      },
    },
  ];
  const exportColumns = [
    { name: "pay_no", title: "Pay-NO" },

    { name: "id", title: "id" },
    { name: "resourceNO", title: "Resource NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    {
      name: "position",
      title: "Position",
    },
    {
      name: "workingDayInMonth",
      title: "Work days in a month",
    },
    {
      name: "deduction",
      title: "Deduction",
    },
    {
      name: "leaveWithoutPay",
      title: "Leave Without Pay",
    },
    {
      name: "absentDays",
      title: "Absent Days",
    },
    {
      name: "daysWorked",
      title: "Days worked",
    },
    {
      name: "monthlySalary",
      title: "Monthly salary ( TL/ USD )",
    },
    {
      name: "idaContractPercentage",
      title: "Contract Percentage",
    },
    {
      name: "contractSalary",
      title: "Contract Salary",
    },
    {
      name: "numberOfHoursLate",
      title: "Number of Hours Late",
    },
    {
      name: "hoursLateDeduction",
      title: "Hours Late Deduction",
    },
    {
      name: "totalSalaryForCurrentMonth",
      title: "Total Salary for current month",
    },
    {
      name: "difference",
      title: "Difference",
    },
    {
      name: "donor",
      title: "Donor",
    },
    {
      name: "project",
      title: "Project",
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
    },
    {
      name: "percentage",
      title: "Percentage",
    },
    {
      name: "allocatedSalary",
      title: "Allocated Salary",
    },
  ];

  return vacationsError || allocationsError || delaysError || accessError || deductionsError || accountantsError ? (
    <p>
      {vacationsError}
      {allocationsError}
      {delaysError}
      {accessError}
      {deductionsError}
      {accountantsError}
    </p>
  ) : (
    <div>
      <MonthPicker
        size="default"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />

      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", marginBottom: "1%", marginRight: "0.5%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for project!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={projectId}
        onChange={(e) => {
          setProjectId(e);
          dispatch(setFilterValue({ key: "projectId", value: e }));
        }}
      >
        {projectList.map((d) => {
          return <Option value={d.id}>{d.projectCode}</Option>;
        })}
      </Select>
      <Select
        placeholder="Filter for Positions!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        mode="multiple"
        showSearch
        value={filteredPosition}
        onChange={(e: any) => {
          setFilteredPosition(e);
        }}
      >
        {positions?.map((d) => {
          return (
            <Option key={d} value={d}>
              {d}
            </Option>
          );
        })}
      </Select>
      <div>
        Payment Office:
        <Select
          placeholder="Payment Office"
          style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
          showSearch
          value={paymentOffice}
          onChange={(e: any) => {
            setPaymentOffice(e);
          }}
        >
          <Option key={"HQ"} value={"HQ"}>
            {"HQ"}
          </Option>
          <Option key={"AZAZ"} value={"AZAZ"}>
            {"AZAZ"}
          </Option>
          <Option key={"Sarmada"} value={"Sarmada"}>
            {"Sarmada"}
          </Option>
          <Option key={"Ghandoura"} value={"Ghandoura"}>
            {"Ghandoura"}
          </Option>
          <Option key={"Telabiad"} value={"Telabiad"}>
            {"Telabiad"}
          </Option>
        </Select>
        Payment Method:
        <Select
          placeholder="Payment Method"
          style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
          showSearch
          value={paymentMethod}
          onChange={(e: any) => {
            setPaymentMethod(e);
          }}
        >
          <Option key={"Cash"} value={"cash"}>
            {"Cash"}
          </Option>
          <Option key={"Transfer"} value={"transfer"}>
            {"Transfer"}
          </Option>
          <Option key={"all"} value={""}>
            {"All"}
          </Option>
        </Select>
        Payment Date:
        <DatePicker
          size="default"
          style={{ marginBottom: "1%", marginRight: "1%" }}
          placeholder="Select month"
          value={paymentDate}
          format={"DD-MM-YYYY"}
          onChange={(e) => setPaymentDate(moment(e))}
        />
        Accountant:
        <Select
          filterOption={(inputValue, option) => {
            return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
          }}
          size="default"
          placeholder="Search for Accountant!"
          style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
          showSearch
          value={accountantId}
          onChange={(e) => {
            setAccountantId(e);
          }}
        >
          {accountants.map((d) => {
            return <Option value={d.uid}>{globalUsersData[d.uid]?.fullName}</Option>;
          })}
        </Select>
      </div>
      <div>
        Exchange Rate:
        <InputNumber
          style={{ marginBottom: "1%", marginRight: "1%", marginLeft: "1%", width: "15%" }}
          value={exchangeRate}
          onChange={(e) => setExchangeRate(e)}
          placeholder={"Exchange Rate"}
        />
        <Checkbox
          checked={fingerPrintActive}
          onChange={(e) => {
            setFingerPrintActive(e.target.checked);
          }}
        >
          Finger-print Activation
        </Checkbox>
        <Button
          size="large"
          icon="print"
          type="primary"
          onClick={() => printPayslip()}
          style={{ marginBottom: "1%", marginRight: "1%" }}
        >
          <Icon type="print" />
          Payslip
        </Button>
      </div>
      <Paper>
        <DevTable
          data={tableData}
          columns={columns}
          defaultHiddenColumnNames={["id"]}
          defaultColumnWidths={defaultColumnWidths}
          tableName={`Payslip-List`}
          selected={false}
          typicalTable={false}
          columnsExport={exportColumns}
          summaryColumn={true}
          totalItems={[
            { columnName: "allocatedSalary", type: "sum" },
            { columnName: "difference", type: "sum" },
            { columnName: "totalSalaryForCurrentMonth", type: "sum" },
          ]}
        />
      </Paper>

      <div id={"multiplePage"} style={{ display: "none" }}>
        {uniqBy(tableData, (u) => u.userId).map((row, index) => (
          <>
            <PaySlipToPrint
              index={index + 1}
              row={row}
              key={index}
              printFnRef={print2CbRef as any}
              month={month}
              days={days}
              paymentOffice={paymentOffice as any}
              exchangeRate={exchangeRate}
              paymentDate={paymentDate}
              accountantId={accountantId}
            />
            <div className="pagebreak" style={{ pageBreakBefore: "always" }} />
          </>
        ))}
      </div>
      {row.allocations ? (
        <div id={"perRow"} style={{ display: "none" }}>
          <>
            <PaySlipToPrint
              index={1}
              row={row}
              key={1}
              printFnRef={print3CbRef as any}
              month={month}
              days={days}
              paymentOffice={paymentOffice as any}
              exchangeRate={exchangeRate}
              paymentDate={paymentDate}
              accountantId={accountantId}
            />
          </>
        </div>
      ) : null}
    </div>
  );
}
