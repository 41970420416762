import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Select, Popconfirm, message } from "antd";
const { Option } = Select;
import UsersInformation from "../models/usersInformation";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import NewUserByUsername from "../models/newUserByUsername";
import { globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { globalUsersData } from "../../global/usersList";
import { deleteUser, editUser, fetchUsersByOfficeId, subscribeToUsers } from "../../features/users/usersSlice";
import { users } from "../../db/interfaces";
import { resetPassword } from "../../features/auth/authSlice";
import { setFilterValue } from "../../features/filters/filtersSlice";

export default function UsersDashboard() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { data: usersByOffice, status, error } = useAppSelector((state) => state.users);
  const { allUsers } = useAppSelector((state) => state.users);

  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const [userRow, setUserRow] = useState({} as users);
  const [modalState, setModalState] = useState(false);
  const [modalStateNewUserByUsername, setModalStateNewUserByUsername] = useState(false);
  const [defaultHiddenColumnNames] = useState([
    "gender",
    "department",
    "vacationPaternityScore",
    "vacationMaternityScore",
    "vacationMarriageScore",
    "vacationDeathScore",
  ]);

  useEffect(() => {
    dispatch(fetchUsersByOfficeId(officeId));
    const unsubscribe = subscribeToUsers(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId]);

  if (status == "loading") return <Spin />;

  const allUsersData = allUsers
    .filter((d) => currentUser?.fullControlOffices?.includes(d.office ?? ""))
    .map((d, index) => {
      return {
        ...d,
        id: d.id,
      };
    });

  const usersByOfficeData = usersByOffice
    .filter((d) => currentUser?.fullControlOffices?.includes(d.office ?? ""))
    .map((d, index) => {
      return {
        ...d,
        id: d.id,
      };
    });

  const data = officeId == "all" ? allUsersData : usersByOfficeData;

  const columnsExport = [
    { name: "pay_no", title: "Pay-NO" },

    {
      name: "status",
      title: "Status",
    },
    { name: "resourceNO", title: "Resource NO" },

    { name: "region", title: "Region" },

    { name: "firstName", title: "firstName" },
    { name: "lastName", title: "lastName" },
    { name: "gender", title: "gender" },
    { name: "email", title: "email" },
    // admins.includes(uid) && { name: "password", title: "password" },
    { name: "admin", title: "admin" },
    { name: "position", title: "position" },
    { name: "managerUID", title: "managerUID" },
    { name: "office", title: "office" },
    { name: "vacationAnnualScore", title: "vacationAnnualScore" },
    { name: "vacationSickScore", title: "vacationSickScore" },
    { name: "vacationCompensationScore", title: "vacationCompensationScore" },
    { name: "vacationDeathScore", title: "vacationDeathScore" },
    { name: "vacationMarriageScore", title: "vacationMarriageScore" },
    { name: "vacationPaternityScore", title: "vacationPaternityScore" },
    { name: "vacationMaternityScore", title: "vacationMaternityScore" },
    { name: "department", title: "department" },
    { name: "adminHR", title: "adminHR" },
    { name: "token", title: "token" },
    { name: "adminLaw", title: "adminLaw" },
    { name: "CEO", title: "CEO" },
    { name: "idaAllocationPercentage", title: "Contract Percentage" },
    { name: "adminOffices", title: "adminOffices" },
    { name: "HROffices", title: "HROffices" },
    { name: "personalPhoto", title: "personalPhoto" },
    { name: "refNO", title: "refNO" },
    { name: "arabicFullName", title: "arabicFullName" },
    { name: "fatherName", title: "fatherName" },
    { name: "motherName", title: "motherName" },
    {
      name: "dateOfBirth",
      title: "Date Of Birth",
      getCellValue: (row: any) => moment(row.dateOfBirth).format("DD.MM.YYYY"),
    },
    { name: "placeOfBirth", title: "placeOfBirth" },
    { name: "permanentAddress", title: "permanentAddress" },
    { name: "currentAddress", title: "currentAddress" },
    { name: "salary", title: "salary" },
    { name: "currency", title: "currency" },

    { name: "bankName", title: "Bank Name" },
    { name: "bankAccountName", title: "(Bank)Full Name" },
    { name: "bankFirstName", title: "(Bank)First Name" },
    { name: "bankLastName", title: "(Bank)Last Name" },
    { name: "IBAN", title: "Iban" },
    { name: "bankAccountNumber", title: "Account Number" },
    { name: "customerNumber", title: "Customer Number" },

    {
      name: "startingDate",
      title: "Starting Date",
      getCellValue: (row: any) => moment(row.startingDate).format("DD.MM.YYYY"),
    },
    {
      name: "endingDate",
      title: "Ending Date",
      getCellValue: (row: any) => moment(row.endingDate).format("DD.MM.YYYY"),
    },
    { name: "phoneNumber", title: "phoneNumber" },
    { name: "identityNo", title: "identityNo" },
    { name: "emergencyContact1", title: "emergencyContact1" },
    { name: "emergencyContact2", title: "emergencyContact2" },
    { name: "status", title: "status" },
    { name: "signatureURL", title: "signatureURL" },
  ];
  const columns = [
    {
      name: "pay_no",
      title: "Pay-NO",
      getCellValue: (row: any) => {
        return parseInt(row.pay_no);
      },
    },

    { name: "resourceNO", title: "Resource NO" },

    { name: "region", title: "Region" },

    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "identityNo", title: "Identity No" },

    { name: "email", title: "Email" },
    { name: "department", title: "Department" },
    { name: "fatherName", title: "Mother Name" },
    { name: "arabicFullName", title: "Arabic FullName" },
    {
      name: "dateOfBirth",
      title: "Date Of Birth",
      getCellValue: (row: any) => moment(row.dateOfBirth).format("DD.MM.YYYY"),
    },
    { name: "placeOfBirth", title: "Place Of Birth" },
    { name: "permanentAddress", title: "Permanent Address" },
    { name: "currentAddress", title: "Current Address" },
    { name: "phoneNumber", title: "Phone Number" },
    { name: "emergencyContact1", title: "EmergencyContact1" },
    { name: "emergencyContact2", title: "EmergencyContact2" },

    { name: "bankName", title: "Bank Name" },
    { name: "bankAccountName", title: "(Bank)Full Name" },
    { name: "bankFirstName", title: "(Bank)First Name" },
    { name: "bankLastName", title: "(Bank)Last Name" },
    { name: "IBAN", title: "Iban" },
    { name: "bankAccountNumber", title: "Account Number" },
    { name: "customerNumber", title: "Customer Number" },

    { name: "salary", title: "Salary" },
    { name: "currency", title: "Currency" },
    {
      name: "startingDate",
      title: "Starting Date",
      getCellValue: (row: any) => moment(row.startingDate).format("DD.MM.YYYY"),
    },
    {
      name: "endingDate",
      title: "Ending Date",
      getCellValue: (row: any) => moment(row.endingDate).format("DD.MM.YYYY"),
    },

    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) => globalUsersData[row.managerUID ?? ""]?.fullName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (e) => globalOfficesData[e.office ?? ""]?.officeName,
    },
    { name: "position", title: "Position" },
    { name: "vacationAnnualScore", title: "Annual Score", getCellValue: (e) => e.vacationAnnualScore?.toFixed(3) },
    { name: "vacationSickScore", title: "Sick Score" },
    {
      name: "vacationCompensationScore",
      title: "Compensation Score",
      getCellValue: (e) => e.vacationCompensationScore.toFixed(3),
    },
    { name: "vacationDeathScore", title: "Death Leave Score", getCellValue: (e) => e.vacationDeathScore?.toFixed(3) },
    { name: "vacationMarriageScore", title: "Marriage Score", getCellValue: (e) => e.vacationMarriageScore?.toFixed(3) },
    {
      name: "vacationMaternityScore",
      title: "Maternity Score",
      getCellValue: (e) => e.vacationMaternityScore.toFixed(3),
    },
    {
      name: "vacationPaternityScore",
      title: "Paternity Score",
      getCellValue: (e) => e.vacationPaternityScore.toFixed(3),
    },
    {
      name: "status",
      title: "Status",
    },
    { name: "idaAllocationPercentage", title: "Contract Percentage" },

    {
      name: "action",
      title: "Edit User Info",
      getCellValue: (r) => {
        return (
          currentUser?.HRProjectsManagersOffices?.includes(r.office) && (
            <Button
              onClick={() => {
                setModalState(true);
                setUserRow(r);
              }}
            >
              Edit
            </Button>
          )
        );
      },
    },
    {
      name: "reset",
      title: "Reset",
      getCellValue: (r) => {
        return (
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={async () => {
              dispatch(resetPassword(r.id)).then((res) => {
                if (res.meta.requestStatus == "fulfilled") message.success(`The user ${r.id} has been reset!`);
                else if (res.meta.requestStatus == "rejected") message.error(res.payload as string);
              });
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Reset</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "disable",
      title: "Disable/Enable",
      getCellValue: (r) => {
        return (
          currentUser?.HRProjectsManagersOffices?.includes(r.office) && (
            <Popconfirm
              title={"Are you sure?"}
              onConfirm={async () => {
                dispatch(editUser({ ...r, status: r.status == "active" ? "disable" : "active" }));
              }}
              onCancel={() => console.log("cancel")}
              okText="Yes"
              cancelText="No"
            >
              <Button type={r.status == "disable" ? "primary" : "danger"}>
                {r.status == "disable" ? "Active" : "Disable"}
              </Button>
            </Popconfirm>
          )
        );
      },
    },
    {
      name: "delete",
      title: "Delete",
      getCellValue: (r) => {
        return (
          currentUser?.HRProjectsManagersOffices?.includes(r.office ?? "") && (
            <Popconfirm
              title={"Are you sure?"}
              onConfirm={async () => {
                dispatch(deleteUser(r.id));
              }}
              onCancel={() => console.log("cancel")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          )
        );
      },
    },
    {
      name: "score",
      title: "Score",
    },
    {
      name: "vacation",
      title: "Vacations",
      getCellValue: (r) => {
        return (
          currentUser?.fullControlOffices?.includes(r.office) && (
            <Popconfirm
              title={"Are you sure?"}
              onConfirm={async () => {
                dispatch(editUser({ ...r, vacationAbility: !r.vacationAbility }));
              }}
              onCancel={() => console.log("cancel")}
              okText="Yes"
              cancelText="No"
            >
              <Button type={r.vacationAbility == undefined || r.vacationAbility ? "danger" : "primary"}>
                {r.vacationAbility == undefined || r.vacationAbility ? "Disable" : "Enable"}
              </Button>
            </Popconfirm>
          )
        );
      },
    },
  ];
  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      {modalState ? <UsersInformation modalState={modalState} setModalState={setModalState} userRow={userRow} /> : null}
      {modalStateNewUserByUsername ? (
        <NewUserByUsername modalState={modalStateNewUserByUsername} setModalState={setModalStateNewUserByUsername} />
      ) : null}
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {[...OFFICES_ID_NAME, { officeId: "all", officeName: "All" }]?.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>
      {currentUser?.HRProjectsManagersOffices?.length != 0 && (
        <>
          <Button
            style={{ margin: "2px" }}
            icon="plus"
            type="primary"
            onClick={() => setModalStateNewUserByUsername(true)}
          >
            Add New Employee
          </Button>
        </>
      )}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName={`Users_List_${globalOfficesData[officeId ?? ""]?.officeName}_${moment().format("MMM").toString()}`}
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
