import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Spin, Button, Select, Icon, Checkbox } from "antd";
const { MonthPicker } = DatePicker;
import { users } from "../../db/interfaces";
import printJS from "print-js";
import _, { orderBy, unionBy } from "lodash";
const { Option } = Select;
import TimeSheetComponent from "./timeSheetComponent";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchVacationsByDate } from "../../features/vacations/vacationsSlice";
import { fetchAllocationsByProjectIdAndDate } from "../../features/allocations/allocationsSlice";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { globalProjectsData } from "../../global/projectList";
import { fetchDelaysByMonth } from "../../features/delays/delaysSlice";
import { fetchShiftsByMonth } from "../../features/shifts/shfitsSlice";
import { setFilterValue } from "../../features/filters/filtersSlice";

export default function TimeSheetByProject() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { currentUser } = useAppSelector((state) => state.auth);
  const {
    data: vacationsData,
    status: vacationsStatus,
    error: vacationsError,
  } = useAppSelector((state) => state.vacations);
  const {
    data: allocationsData,
    status: allocationsStatus,
    error: allocationsError,
  } = useAppSelector((store) => store.allocations);
  const { data: delaysData, status: delaysStatus, error: delaysError } = useAppSelector((store) => store.delays);
  const { data: shiftsData, status: shiftsStatus, error: shiftsError } = useAppSelector((store) => store.shifts);

  const dispatch = useAppDispatch();
  const [month, setMonth] = useState(moment());
  const [uid, setUid] = useState("Select an employee..");
  const [projectId, setProjectId] = useState(filterValues["projectId"] ?? "Select a project...");
  const [activeDelay, setActiveDelay] = useState(true);

  useEffect(() => {
    dispatch(
      fetchVacationsByDate({
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
        byField: "vacationStartOn",
      })
    );
    dispatch(
      fetchAllocationsByProjectIdAndDate({
        projectId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
      })
    );
    dispatch(fetchDelaysByMonth({ month: month.format("MMM.YYYY").toString() }));
    dispatch(fetchShiftsByMonth({ month: month.format("MM-YYYY").toString() }));
  }, [dispatch, month, projectId]);

  if (
    vacationsStatus == "loading" ||
    allocationsStatus == "loading" ||
    delaysStatus == "loading" ||
    shiftsStatus == "loading"
  )
    return <Spin />;

  const usersByProject = unionBy(
    allocationsData
      .map((allocation) => {
        const user = globalUsersData[allocation.uid ?? ""];
        return user;
      })
      .filter((user) => user != undefined),
    (user) => user.id
  );

  const projectList = Object.values(globalProjectsData).map((p) => {
    return { id: p.id, projectCode: p?.projectCode };
  });

  const print = async () => {
    await printJS({
      printable: "timeSheet2",
      type: "html",
      targetStyles: ["*"],
      scanStyles: true,
      font_size: "15",
      showModal: true,
    });
  };
  return vacationsError || allocationsError || delaysError || shiftsError ? (
    <p>
      {vacationsError}
      {allocationsError}
      {delaysError}
      {shiftsError}
    </p>
  ) : (
    <div>
      <MonthPicker
        allowClear={false}
        size="default"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        style={{ width: "25%", marginBottom: "1%", marginRight: "0.5%" }}
        allowClear
        showSearch
        value={uid}
        onChange={(e) => {
          if (e == undefined) {
            setUid("Select an employee..");
          } else setUid(e);
        }}
      >
        {USERS_ID_FULL_NAME?.map((d) => {
          return <Option value={d.uid}>{d.fullName}</Option>;
        })}
      </Select>

      <Select
        size="default"
        placeholder="Search for project!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        value={projectId}
        onChange={(e) => {
          setProjectId(e);
          dispatch(setFilterValue({ key: "projectId", value: e }));
        }}
      >
        {projectList.map((d) => {
          return <Option value={d.id}>{d.projectCode}</Option>;
        })}
      </Select>
      {currentUser?.fullControlOffices?.length != 0 ? (
        <Checkbox
          style={{ marginLeft: "1%" }}
          checked={activeDelay}
          value={activeDelay}
          onChange={(e) => {
            setActiveDelay(!activeDelay);
          }}
        >
          Active
        </Checkbox>
      ) : null}

      <Button
        style={{ marginLeft: "1%" }}
        type="primary"
        onClick={async () => {
          print();
        }}
        htmlType="submit"
      >
        Print
      </Button>

      <div id="timeSheet2">
        {orderBy(usersByProject, (x) => parseInt(x.pay_no ?? "emp"))
          .filter((user: any) => user?.status != "disable")
          .filter((user) => (uid == "Select an employee.." || uid == "all" ? true : user?.id == uid))
          .map((user) => {
            return (
              <div>
                <TimeSheetComponent
                  month={month}
                  uid={user?.id as string}
                  userData={user as users}
                  vacationsData={vacationsData.filter((vacation) => vacation.employeeUID == user?.id)}
                  allocationsData={allocationsData.filter((allocation) => allocation.uid == user?.id)}
                  delaysData={delaysData.filter((delay) => delay.userUID == user?.id)}
                  shiftsData={shiftsData.filter((shift) => shift.uid == user?.id)}
                  activeDelay={activeDelay}
                />
                <div style={{ pageBreakAfter: "always" }} />
              </div>
            );
          })}
        <br />
      </div>
    </div>
  );
}
