import React, { useState, useRef, useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Select, Button, DatePicker } from "antd";
const { Option } = Select;
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { orderBy, sortBy } from "lodash";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import VacationView from "./vacationView";
import { vacation } from "../../db/interfaces";
const { MonthPicker } = DatePicker;
import Picture1 from "./Picture1.png";
import Picture2 from "./Picture2.png";
import { globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchVacationsByOfficeByDate, subscribeToVacations } from "../../features/vacations/vacationsSlice";
import { globalUsersData } from "../../global/usersList";
import { setFilterValue } from "../../features/filters/filtersSlice";

export default function VacationsTable() {
  const history = useHistory();
  const { t } = useTranslation();
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: vacationsData, status, error } = useAppSelector((state) => state.vacations);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [month, setMonth] = useState(moment());
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const uid = currentUser.id as string;
  const [defaultHiddenColumnNames] = useState(["vacationAnnualScore", "vacationSickScore"]);
  const print1CbRef = useRef<() => void>();
  const print2CbRef = useRef<() => void>();

  const print = useCallback(() => {
    setTimeout(() => {
      print1CbRef.current();
    }, 1500);
  }, []);

  const printCb = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .lineImageDivider {
          padding: 0%;
          width: 100%;
          height: 2px;
        }
        .sig{
          position: absolute;
          transform: translate(-50%, -50%);
        
        } 
        .sigEMP{
          position: absolute;
          transform: translate(-50%, -59%);
        
        } 
    
        .container {  height: 1450px; width: 1027px;  position: relative;  z-index: 1;}.container .bg {  position: absolute;  z-index: -1;  top: 0;  bottom: 0;  left: 0;  right: 0;  background: url(${
          globalOfficesData[officeId ?? ""]?.cacheMissionName == "IRAQ" ? Picture2 : Picture1
        });  background-repeat: no-repeat;  background-size: 1020px;  opacity: 0.5; }.lineImage {  padding: 0%;  width: 100%;}img {  pointer-events: none;}.tg {  border-collapse: collapse;  border-spacing: 0;}.tg td {  border-color: black;  border-style: solid;  border-width: 1px;  font-family: Arial, sans-serif;  font-size: 14px;  overflow: hidden;  padding: 8px 5px;  word-break: normal;}.tg th {  border-color: black;  border-style: solid;  border-width: 1px;  font-family: Arial, sans-serif;  font-size: 14px;  font-weight: normal;  overflow: hidden;  padding: 8px 5px;  word-break: normal;}.tg .tg-1tm2 {  border: none;  font-size: 18px;  text-align: left;  text-decoration: underline;  vertical-align: bottom;}.tg .tg-z0zb {  border: none;  font-size: 18px;  font-weight: bold;  text-align: left;  vertical-align: top;}.tg .tg-xymo {  border: none;  font-size: 18px;  text-align: left;  vertical-align: bottom;}.tg .tg-y3sf {  border: none;  font-size: 22px;  font-weight: bold;  text-align: center;  text-decoration: underline;  vertical-align: middle;}.tg .tg-nwlk {  border: none;  font-size: 18px;  text-align: center;  text-decoration: underline;  vertical-align: bottom;}.tg .tg-fa7u {  border: none;  font-size: 18px;  text-align: left;  vertical-align: top;}.tg .tg-b420 {  border: none;  font-size: 18px;  text-align: center;  vertical-align: top;}.tg .tg-czk2 {  border: none;  font-size: 18px;  text-align: center;  vertical-align: bottom;}</style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`printingAll`);
  }, [vacationsData]);

  useEffect(() => {
    dispatch(
      fetchVacationsByOfficeByDate({
        officeId: officeId == "Select an office..." ? currentUser?.office : officeId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
        byField: "vacationStartOn",
      })
    );

    const unsubscribe = subscribeToVacations(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId, month]);

  if (status == "loading") return <Spin />;

  print1CbRef.current = printCb;

  const sortedData = sortBy(
    vacationsData?.map((d, index) => {
      return {
        ...d,
        id: d.id,
        index: index + 1,
        employeeUID: d?.employeeUID,
        managerUID: d.managerUID,
        office: d.office,
        requestedAt: d.requestedAt,
      };
    }),
    (item) => item.requestedAt
  ).reverse();

  const userPermissionData =
    currentUser?.fullControlOffices?.length != 0 || currentUser?.CEO == true
      ? sortedData.filter(
          (e: any) => currentUser?.fullControlOffices?.includes(e.office) || e.employeeUID == uid || e.managerUID == uid
        )
      : sortedData.filter((e: any) => e.employeeUID == uid || e.managerUID == uid);

  const columns = [
    {
      name: "pay_no",
      title: "Pay-No",
      getCellValue: (row: any) => parseInt(globalUsersData[row.employeeUID ?? ""]?.pay_no ?? "emp"),
    },
    {
      name: "uid",
      title: "UID",
      getCellValue: (row: any) => row.employeeUID,
    },
    {
      name: "vacationType",
      title: "Type",
    },
    {
      name: "vacationPeriodDays",
      title: "Days",
    },

    {
      name: "employeeUID",
      title: "Full Name",
      getCellValue: (row: any) => globalUsersData[row.employeeUID ?? ""]?.fullName,
    },
    {
      name: "position",
      title: "Position",
    },
    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) => globalUsersData[row.managerUID ?? ""]?.fullName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office ?? ""].officeName,
    },
    {
      name: "status",
      title: "Status",
    },
    {
      name: "vacationStartOn",
      title: "Vacation Start",
      getCellValue: (row: any) => moment(row.vacationStartOn).format("DD.MM.YYYY"),
    },
    {
      name: "vacationEndOn",
      title: "Vacation End",
      getCellValue: (row: any) => moment(row.vacationEndOn).format("DD.MM.YYYY"),
    },
    {
      name: "startWorkOn",
      title: "Start Work",
      getCellValue: (row: any) => moment(row.startWorkOn).format("DD.MM.YYYY"),
    },
    {
      name: "vacationAnnualScore",
      title: "Annual Score",
    },
    {
      name: "vacationSickScore",
      title: "Sick Score",
    },
    {
      name: "RFLURL",
      title: "RFL",
      getCellValue: (row) => {
        return row.RFLURL == "" || row.RFLURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              var link = document.createElement("a");

              if (link.download !== undefined) {
                link.setAttribute("href", row.RFLURL);
                link.setAttribute("target", "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
          >
            Download
          </Button>
        );
      },
    },
    {
      name: "archivedRFL",
      title: "Archived RFL",
      getCellValue: (row) => {
        return row.archivedRFL == "" || row.archivedRFL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              var link = document.createElement("a");
              if (link.download !== undefined) {
                link.setAttribute("href", row.archivedRFL);
                link.setAttribute("target", "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                console.log(
                  encodeURIComponent(
                    "https://firebasestorage.googleapis.com/v0/b/administrationapp-cdc55.appspot.com/o/kGtYGGmUJGM63RmUhKBNHhCrHWB2%2Fc5371b07-67d5-4666-8494-f5e29f33e8de.jpg?alt=media&token=f5e2ee1b-73b5-41b7-a971-0b59e838d0a9"
                  )
                );
                link.click();
                document.body.removeChild(link);
              }
            }}
          >
            Download
          </Button>
        );
      },
    },
    {
      name: "more",
      title: "More Information",
      getCellValue: (row: any) => {
        return <Button onClick={() => history.push(`/vacation/${row.id}`)}> {t("general.view")}</Button>;
      },
    },
  ];
  const columnsExport = [
    {
      name: "pay_no",
      title: "Pay-No",
      getCellValue: (row: any) => globalUsersData[row.employeeUID ?? ""]?.pay_no,
    },
    {
      name: "uid",
      title: "UID",
      getCellValue: (row: any) => row.employeeUID,
    },
    {
      name: "vacationType",
      title: "Type",
    },
    {
      name: "vacationPeriodDays",
      title: "Days",
    },

    {
      name: "employeeUID",
      title: "Full Name",
      getCellValue: (row: any) => globalUsersData[row.employeeUID ?? ""]?.fullName,
    },
    {
      name: "position",
      title: "Position",
    },
    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) => globalUsersData[row.managerUID ?? ""]?.fullName,
    },
    {
      name: "office",
      title: "Location",
    },
    {
      name: "status",
      title: "Status",
    },
    {
      name: "vacationStartOn",
      title: "Vacation Start",
      getCellValue: (row: any) => moment(row.vacationStartOn).format("DD.MM.YYYY"),
    },
    {
      name: "vacationEndOn",
      title: "Vacation End",
      getCellValue: (row: any) => moment(row.vacationEndOn).format("DD.MM.YYYY"),
    },
    {
      name: "startWorkOn",
      title: "Start Work",
      getCellValue: (row: any) => moment(row.startWorkOn).format("DD.MM.YYYY"),
    },
    {
      name: "vacationAnnualScore",
      title: "Annual Score",
    },
    {
      name: "vacationSickScore",
      title: "Sick Score",
    },
    {
      name: "RFLURL",
      title: "RFL",
    },
    {
      name: "archivedRFL",
      title: "Archived RFL",
    },
  ];

  const printAllVacations = () => {
    print();
  };

  return (
    <div>
      <MonthPicker
        style={{ marginBottom: "1%" }}
        size="default"
        placeholder="Select month"
        value={month}
        format={"MMMM-YYYY"}
        onChange={(e) => setMonth(moment(e))}
      />
      {currentUser?.fullControlOffices?.length != 0 || currentUser.CEO ? (
        <Select
          filterOption={(inputValue, option) => {
            return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
          }}
          size="default"
          placeholder="Search for Office!"
          style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
          showSearch
          value={officeId}
          onChange={(e) => {
            setOfficeId(e);
            dispatch(setFilterValue({ key: "officeId", value: e }));
          }}
        >
          {OFFICES_ID_NAME.map((d) => {
            return <Option value={d.officeId}>{d.officeName}</Option>;
          })}
        </Select>
      ) : null}

      <Button style={{ marginLeft: "10px" }} onClick={printAllVacations}>
        Print All
      </Button>
      <div>
        <Paper>
          <DevTable
            data={userPermissionData}
            columns={columns}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            tableName="Vacations_List"
            selected={false}
            typicalTable={false}
            columnsExport={columnsExport}
          />
        </Paper>
      </div>
      <div
        id="printingAll"
        style={{
          display: "none",
        }}
      >
        {orderBy(userPermissionData, (x) => parseInt(globalUsersData[x.employeeUID ?? ""]?.pay_no ?? "emp"))
          .filter((vacation: any) => vacation.status == "Accepted")
          .map((vacation) => {
            return <VacationView vacation={vacation as vacation | any} printFnRef={print2CbRef as any} />;
          })}
      </div>
    </div>
  );
}
