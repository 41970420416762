import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, DatePicker, Drawer, Form, InputNumber, message, Select, Input, Descriptions } from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { globalUsersData } from "../../../global/usersList";
import { editUser } from "../../../features/users/usersSlice";
import { addVacation } from "../../../features/vacations/vacationsSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  vacationStartOn: Yup.date().required("*"),
});

function AddNewVacation(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [vacationType, setVacationType] = useState("");
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const [halfDay, setHalfDay] = useState("morning");
  const history = useHistory();
  const uid = currentUser?.id as string;
  const userData = globalUsersData[uid ?? ""];
  const {
    vacationAnnualScore,
    vacationSickScore,
    vacationCompensationScore,
    position,
    managerUID,
    office,
    vacationDeathScore,
    vacationMarriageScore,
    vacationPaternityScore,
    vacationMaternityScore,
    signatureURL,
  } = currentUser;

  const changeOnScore = async (vacationPeriodDays: number) => {
    const takenScore = vacationPeriodDays;
    const userId = uid;
    switch (vacationType) {
      case "Annual Leave":
        await dispatch(
          editUser({
            id: userId,
            vacationAnnualScore: globalUsersData[userId ?? ""]?.vacationAnnualScore - takenScore,
          })
        );
        break;

      case "Sick Leave":
        await dispatch(
          editUser({
            id: userId,
            vacationSickScore: globalUsersData[userId ?? ""]?.vacationSickScore - takenScore,
          })
        );
        break;

      case "Compensation Leave":
        await dispatch(
          editUser({
            id: userId,
            vacationCompensationScore: globalUsersData[userId ?? ""]?.vacationCompensationScore - takenScore,
          })
        );
        break;

      case "Death Leave":
        await dispatch(
          editUser({
            id: userId,
            vacationDeathScore: globalUsersData[userId ?? ""]?.vacationDeathScore - takenScore,
          })
        );
        break;

      case "Marriage Leave":
        await dispatch(
          editUser({
            id: userId,
            vacationMarriageScore: globalUsersData[userId ?? ""]?.vacationMarriageScore - takenScore,
          })
        );
        break;
      case "Paternity Leave":
        await dispatch(
          editUser({
            id: userId,
            vacationPaternityScore: globalUsersData[userId ?? ""]?.vacationPaternityScore - takenScore,
          })
        );
        break;

      case "Maternity Leave":
        await dispatch(
          editUser({
            id: userId,
            vacationMaternityScore: globalUsersData[userId ?? ""]?.vacationMaternityScore - takenScore,
          })
        );
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: {
      vacationType: "",
      notes: "",
      vacationStartOn: moment(),
      vacationEndOn: moment(),
      startWorkOn: moment(),
      vacationPeriodDays: 0,
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      const typeScore =
        vacationType == "Annual Leave"
          ? vacationAnnualScore
          : vacationType == "Sick Leave"
          ? vacationSickScore
          : vacationType == "Compensation Leave"
          ? vacationCompensationScore
          : 120;
      if (values.vacationPeriodDays > typeScore || values.vacationPeriodDays == 0 || vacationType == "") {
        alert(`Your ${vacationType} Score is not enough as requested!`);
        setLoading(false);
      } else {
        dispatch(
          addVacation({
            employeeUID: uid,
            position: position ?? userData.position ?? "",
            managerUID: managerUID,
            vacationStartOn: values.vacationStartOn as any,
            vacationEndOn: values.vacationEndOn as any,
            startWorkOn: values.startWorkOn as any,
            employeeSignatureDate: moment() as any,
            managerSignatureDate: "",
            adminSignatureDate: "",
            HRSignatureDate: "",
            office: office,
            vacationType: vacationType as any,
            vacationPeriodDays: values.vacationPeriodDays,
            vacationAnnualScore: vacationAnnualScore,
            vacationSickScore: vacationSickScore,
            vacationCompensationScore: vacationCompensationScore,
            vacationDeathScore: vacationDeathScore,
            vacationMarriageScore: vacationMarriageScore,
            vacationPaternityScore: vacationPaternityScore,
            vacationMaternityScore: vacationMaternityScore,
            status: "Requested",
            requestedAt: moment() as any,
            notes: values.notes,
            halfDay: values.vacationPeriodDays == 0.5 ? halfDay : "",
            employeeSignatureURL: signatureURL == undefined ? "" : signatureURL,
            managerSignatureURL: "",
            HRSignatureURL: "",
            adminSignatureURL: "",
            version: 2,
            adminUID: "",
            HRUserUID: "",
            canceledBy: "",
            rejectedBy: "",
            vacationCompensationAcceptedDate: "",
            RFLURL: "",
            archivedRFL: "",
          })
        )
          .then((res: any) => {
            history.push(`/vacation/${res.payload.id}`);
            changeOnScore(values.vacationPeriodDays).then(() => window.location.reload());
          })
          .then(() => {
            message.success("Your request has been submitted successfully");
            setModalState(false);
            setLoading(false);
            setLoading(false);
          })

          .then(() => form.resetForm())

          .catch((error) => {
            console.error("Error writing document: ", error);
            message.error("Failed");
          });
      }
    },
  });

  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  let maxNumber = 0;
  switch (vacationType) {
    case "Annual Leave":
      maxNumber = vacationAnnualScore;
      break;
    case "Sick Leave":
      maxNumber = vacationSickScore;
      break;
    case "Death Leave":
      maxNumber = vacationDeathScore;
      break;
    case "Marriage Leave":
      maxNumber = vacationMarriageScore;
      break;
    case "Paternity Leave":
      maxNumber = vacationPaternityScore;
      break;
    case "Maternity Leave":
      maxNumber = vacationMaternityScore;
      break;
    case "Compensation Leave":
      maxNumber = vacationCompensationScore;
      break;

    default:
      maxNumber = 120;
      break;
  }

  return (
    <Drawer title="Vacation Request" width={600} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.vacationType")} span={6}>
                <Select value={vacationType} onChange={(e: string) => setVacationType(e)}>
                  <Option value={"Annual Leave"}>Annual Leave</Option>
                  <Option value={"Unpaid Leave"}>Unpaid Leave</Option>
                  <Option value={"Sick Leave"}>Sick Leave</Option>
                  <Option value={"Death Leave"}>Death Leave</Option>
                  <Option value={"Marriage Leave"}>Marriage Leave</Option>
                  <Option value={"Paternity Leave"}>Paternity Leave</Option>
                  <Option value={"Maternity Leave"}>Maternity Leave</Option>
                  <Option value={"Compensation Leave"}>Compensation Leave</Option>
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationStartOn")} span={6}>
                <DatePicker
                  value={formik.values.vacationStartOn}
                  format={dateFormat}
                  onChange={(e: any) => {
                    if (currentUser?.fullControlOffices?.length != 0) {
                      formik.setFieldValue("vacationStartOn", e);
                    } else {
                      if (vacationType == "Sick Leave") {
                        formik.setFieldValue("vacationStartOn", e);
                      } else {
                        if (moment(e) >= moment()) {
                          formik.setFieldValue("vacationStartOn", e);
                        } else {
                          formik.setFieldValue("vacationStartOn", moment());
                        }
                      }
                    }
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationEndOn")} span={6}>
                <DatePicker
                  value={formik.values.vacationEndOn}
                  format={dateFormat}
                  onChange={(e: any) => {
                    if (currentUser?.fullControlOffices?.length != 0) {
                      formik.setFieldValue("vacationEndOn", e);
                    } else {
                      if (vacationType == "Sick Leave") {
                        formik.setFieldValue("vacationEndOn", e);
                      } else {
                        if (moment(e) >= moment()) {
                          formik.setFieldValue("vacationEndOn", e);
                        } else {
                          formik.setFieldValue("vacationEndOn", moment());
                        }
                      }
                    }
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.startWorkOn")} span={6}>
                <DatePicker
                  value={formik.values.startWorkOn}
                  format={dateFormat}
                  onChange={(e: any) => {
                    formik.setFieldValue("startWorkOn", e);
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationPeriodDays")} span={6}>
                <InputNumber
                  max={maxNumber}
                  min={0}
                  value={formik.values.vacationPeriodDays}
                  onChange={(e: any) => {
                    if (e > maxNumber) {
                      formik.setFieldValue("vacationPeriodDays", maxNumber == 0.5 ? maxNumber : Math.floor(maxNumber));
                    } else {
                      formik.setFieldValue("vacationPeriodDays", e == 0.5 ? e : Math.floor(e));
                    }
                  }}
                />
              </Descriptions.Item>
              {formik.values.vacationPeriodDays == 0.5 ? (
                <Descriptions.Item label={t("general.halfDay")} span={6}>
                  <Select value={halfDay} onChange={(e) => setHalfDay(e)}>
                    <Option value={"morning"}>Morning</Option>
                    <Option value={"evening"}>Evening</Option>
                  </Select>
                </Descriptions.Item>
              ) : null}

              <Descriptions.Item label={t("general.notes")} span={6}>
                <TextArea
                  value={formik.values.notes}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={(e) => formik.setFieldValue("notes", e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewVacation;
