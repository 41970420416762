import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Select, Popconfirm, message } from "antd";
const { Option } = Select;
import UsersInformation from "../models/usersInformation";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchAllocationsByProjectId, subscribeToAllocations } from "../../features/allocations/allocationsSlice";
import { globalUsersData } from "../../global/usersList";
import { globalOfficesData } from "../../global/officeList";
import { fetchAllProjects } from "../../features/projects/projectsSlice";
import { globalBudgetLinesData } from "../../global/budgetLineList";
import { resetPassword } from "../../features/auth/authSlice";
import { deleteUser, editUser } from "../../features/users/usersSlice";
import NewUserByUsername from "../models/newUserByUsername";
import { users } from "../../db/interfaces";

export default function UsersDashboardByProject() {
  const { data: allocationsData, status, error } = useAppSelector((store) => store.allocations);
  const { data: projectsData } = useAppSelector((store) => store.projects);
  const { currentUser } = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();
  const [userRow, setUserRow] = useState({} as users);
  const [modalStateNewUserByUsername, setModalStateNewUserByUsername] = useState(false);
  const [projectId, setProjectId] = useState("Select a project..");
  const [modalState, setModalState] = useState(false);
  const [defaultHiddenColumnNames] = useState([
    "gender",
    "department",
    "vacationPaternityScore",
    "vacationMaternityScore",
    "vacationMarriageScore",
    "vacationDeathScore",
  ]);
  useEffect(() => {
    dispatch(
      fetchAllocationsByProjectId({
        projectId,
      })
    );
    dispatch(fetchAllProjects());

    const unsubscribe = subscribeToAllocations(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, projectId]);

  if (status == "loading") return <Spin />;

  const projectList = projectsData.map((p) => {
    return { id: p.id, projectCode: p?.projectCode };
  });

  const userAllocation = allocationsData.map((allocation) => {
    const user = globalUsersData[allocation.uid ?? ""];
    return { ...user, ...allocation };
  });
  const columnsExport = [
    { name: "pay_no", title: "Pay-NO" },

    { name: "resourceNO", title: "Resource NO" },
    {
      name: "budgetLineId",
      title: "BudgetLine",
      getCellValue: (row: any) => {
        return globalBudgetLinesData[row.budgetLineId ?? ""]?.budgetLine;
      },
    },
    {
      name: "idaAllocationPercentage",
      title: "Contract %",
      getCellValue: (r) => r.idaAllocationPercentage + " " + "%",
    },
    { name: "percentage", title: "Allocation %", getCellValue: (r) => r.percentage + " " + "%" },
    {
      name: "startDate",
      title: "Start %",
      getCellValue: (row: any) => {
        return row.startDate ? moment(row.startDate).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "endDate",
      title: "End %",
      getCellValue: (row: any) => {
        return row.endDate ? moment(row.endDate).format("DD.MM.YYYY") : "";
      },
    },

    { name: "region", title: "Region" },
    { name: "firstName", title: "firstName" },
    { name: "lastName", title: "lastName" },
    { name: "gender", title: "gender" },
    { name: "email", title: "email" },
    // { name: "password", title: "password" },
    { name: "admin", title: "admin" },
    { name: "position", title: "position" },
    { name: "managerUID", title: "managerUID" },
    { name: "office", title: "office" },
    { name: "vacationAnnualScore", title: "vacationAnnualScore" },
    { name: "vacationSickScore", title: "vacationSickScore" },
    { name: "vacationCompensationScore", title: "vacationCompensationScore" },
    { name: "vacationDeathScore", title: "vacationDeathScore" },
    { name: "vacationMarriageScore", title: "vacationMarriageScore" },
    { name: "vacationPaternityScore", title: "vacationPaternityScore" },
    { name: "vacationMaternityScore", title: "vacationMaternityScore" },
    { name: "department", title: "department" },
    { name: "adminHR", title: "adminHR" },
    { name: "token", title: "token" },
    { name: "adminLaw", title: "adminLaw" },
    { name: "CEO", title: "CEO" },
    { name: "idaAllocationPercentage", title: "Contract Percentage" },
    {
      name: "status",
      title: "Status",
    },

    { name: "adminOffices", title: "adminOffices" },
    { name: "HROffices", title: "HROffices" },
    { name: "personalPhoto", title: "personalPhoto" },
    { name: "refNO", title: "refNO" },
    { name: "arabicFullName", title: "arabicFullName" },
    { name: "fatherName", title: "fatherName" },
    { name: "motherName", title: "motherName" },
    { name: "dateOfBirth", title: "dateOfBirth" },
    { name: "placeOfBirth", title: "placeOfBirth" },
    { name: "permanentAddress", title: "permanentAddress" },
    { name: "currentAddress", title: "currentAddress" },
    { name: "salary", title: "salary" },
    { name: "currency", title: "currency" },
    { name: "startingDate", title: "startingDate" },
    { name: "endingDate", title: "endingDate" },
    { name: "phoneNumber", title: "phoneNumber" },
    { name: "identityNo", title: "identityNo" },
    { name: "emergencyContact1", title: "emergencyContact1" },
    { name: "emergencyContact2", title: "emergencyContact2" },
    { name: "bankAccountName", title: "bankAccountName" },
    { name: "bankName", title: "bankName" },
    { name: "IBAN", title: "IBAN" },
    { name: "bankAccountNumber", title: "bankAccountNumber" },
    { name: "status", title: "status" },
    { name: "signatureURL", title: "signatureURL" },
  ];
  const columns = [
    {
      name: "pay_no",
      title: "Pay-NO",
      getCellValue: (row: any) => {
        return parseInt(row.pay_no);
      },
    },

    { name: "resourceNO", title: "Resource NO" },
    {
      name: "budgetLineId",
      title: "BudgetLine",
      getCellValue: (row: any) => {
        return globalBudgetLinesData[row.budgetLineId ?? ""]?.budgetLine;
      },
    },
    {
      name: "idaAllocationPercentage",
      title: "Contract %",
      getCellValue: (r) => r.idaAllocationPercentage + " " + "%",
    },
    { name: "percentage", title: "Allocation %", getCellValue: (r) => r.percentage + " " + "%" },
    {
      name: "startDate",
      title: "Start %",
      getCellValue: (row: any) => {
        return row.startDate ? moment(row.startDate).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "endDate",
      title: "End %",
      getCellValue: (row: any) => {
        return row.endDate ? moment(row.endDate).format("DD.MM.YYYY") : "";
      },
    },

    { name: "region", title: "Region" },

    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "identityNo", title: "Identity No" },

    { name: "email", title: "Email" },
    // { name: "password", title: "Password" },
    { name: "department", title: "Department" },
    { name: "fatherName", title: "Mother Name" },
    { name: "arabicFullName", title: "Arabic FullName" },
    {
      name: "dateOfBirth",
      title: "Date Of Birth",
      getCellValue: (row: any) => {
        return row.dateOfBirth ? moment(row.dateOfBirth).format("DD.MM.YYYY") : "";
      },
    },
    { name: "placeOfBirth", title: "Place Of Birth" },
    { name: "permanentAddress", title: "Permanent Address" },
    { name: "currentAddress", title: "Current Address" },
    { name: "phoneNumber", title: "Phone Number" },
    { name: "emergencyContact1", title: "EmergencyContact1" },
    { name: "emergencyContact2", title: "EmergencyContact2" },
    { name: "bankAccountName", title: "Bank AccountName" },
    { name: "IBAN", title: "IBAN" },
    { name: "salary", title: "Salary" },
    { name: "currency", title: "Currency" },
    {
      name: "startingDate",
      title: "Starting Date",
      getCellValue: (row: any) => {
        return row.startingDate ? moment(row.startingDate).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "endingDate",
      title: "Ending Date",
      getCellValue: (row: any) => {
        return row.endingDate ? moment(row.endingDate).format("DD.MM.YYYY") : "";
      },
    },

    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) => globalUsersData[row.managerUID ?? ""]?.fullName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (e) => globalOfficesData[e.office ?? ""]?.officeName,
    },
    { name: "position", title: "Position" },
    { name: "vacationAnnualScore", title: "Annual Score", getCellValue: (e) => e.vacationAnnualScore?.toFixed(3) },
    { name: "vacationSickScore", title: "Sick Score" },
    {
      name: "vacationCompensationScore",
      title: "Compensation Score",
      getCellValue: (e) => e.vacationCompensationScore?.toFixed(3),
    },
    { name: "vacationDeathScore", title: "Death Leave Score", getCellValue: (e) => e.vacationDeathScore?.toFixed(3) },
    {
      name: "vacationMarriageScore",
      title: "Marriage Score",
      getCellValue: (e) => e.vacationMarriageScore?.toFixed(3),
    },
    {
      name: "vacationMaternityScore",
      title: "Maternity Score",
      getCellValue: (e) => e.vacationMaternityScore?.toFixed(3),
    },
    {
      name: "vacationPaternityScore",
      title: "Paternity Score",
      getCellValue: (e) => e.vacationPaternityScore?.toFixed(3),
    },
    { name: "idaAllocationPercentage", title: "Contract Percentage" },
    {
      name: "status",
      title: "Status",
    },

    {
      name: "action",
      title: "Edit User Info",
      getCellValue: (r) => {
        return (
          currentUser?.HRProjectsManagersOffices.includes(r.office) && (
            <Button
              onClick={() => {
                setModalState(true);
                setUserRow(r);
              }}
            >
              Edit
            </Button>
          )
        );
      },
    },
    {
      name: "reset",
      title: "Reset",
      getCellValue: (r) => {
        return (
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={async () => {
              dispatch(resetPassword(r.id)).then((res) => {
                if (res.meta.requestStatus == "fulfilled") message.success(`The user ${r.id} has been reset!`);
                else if (res.meta.requestStatus == "rejected") message.error(res.payload as string);
              });
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Reset</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "disable",
      title: "Disable/Enable",
      getCellValue: (r) => {
        return (
          currentUser?.HRProjectsManagersOffices.includes(r.office) && (
            <Popconfirm
              title={"Are you sure?"}
              onConfirm={async () => {
                dispatch(editUser({ ...r, status: r.status == "active" ? "disable" : "active" }));
              }}
              onCancel={() => console.log("cancel")}
              okText="Yes"
              cancelText="No"
            >
              <Button type={r.status == "disable" ? "primary" : "danger"}>
                {r.status == "disable" ? "Active" : "Disable"}
              </Button>
            </Popconfirm>
          )
        );
      },
    },
    {
      name: "delete",
      title: "Delete",
      getCellValue: (r) => {
        return currentUser?.HRProjectsManagersOffices.includes(r.office) ? (
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={async () => {
              dispatch(deleteUser(r.id));
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        ) : null;
      },
    },
  ];

  return (
    <div>
      {modalState ? <UsersInformation modalState={modalState} setModalState={setModalState} userRow={userRow} /> : null}
      {modalStateNewUserByUsername ? (
        <NewUserByUsername modalState={modalStateNewUserByUsername} setModalState={setModalStateNewUserByUsername} />
      ) : null}
      <Select
        size="default"
        placeholder="Search for project!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        value={projectId}
        onChange={(e) => {
          setProjectId(e);
        }}
      >
        {projectList.map((d) => {
          return <Option value={d.id}>{d.projectCode}</Option>;
        })}
      </Select>
      {currentUser?.HRProjectsManagersOffices?.length != 0 && (
        <>
          <Button
            style={{ margin: "2px" }}
            icon="plus"
            type="primary"
            onClick={() => setModalStateNewUserByUsername(true)}
          >
            Add New Employee
          </Button>
        </>
      )}
      <Paper>
        <DevTable
          data={userAllocation}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName={`Users-List`}
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
