import moment, { Moment } from "moment";
import { getOffices } from "./userGlobal";
import _ from "lodash";

export const currentOffice = (userUID: string, date: Moment) => {
  const offices = getOffices();
  // Filter the logs by the userUID using Lodash's _.filter
  const userLogs = _.filter(offices, { uid: userUID });

  // Sort the logs by the startDate using Lodash's _.sortBy
  const sortedLogs = _.sortBy(userLogs, (log) => log.startDate.valueOf());

  // Find the log that matches the targetDate using Lodash's _.findLast
  const officeLog = _.findLast(sortedLogs, (log) => date.isSameOrAfter(moment(log.startDate).startOf("day")));

  return officeLog ? officeLog?.office : undefined;
};
