import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Input,
  Descriptions,
  DatePicker,
  Radio,
  Checkbox,
  Row,
  TimePicker,
} from "antd";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { users } from "../../db/interfaces";
import moment from "moment";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { globalOfficesData, ALL_OFFICES_ID_NAME } from "../../global/officeList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editUser } from "../../features/users/usersSlice";
import { addPosition } from "../../features/positions/positionsSlice";
import { addSalary } from "../../features/salaries/salariesSlice";
import { addWorkOffice } from "../../features/workOffice/workOfficeSlice";
import { addSupervisor } from "../../features/supervisors/supervisorsSlice";
import { addPaymentType } from "../../features/paymentTypes/paymentTypesSlice";
import { addContractPercentage } from "../../features/contractPercentage/contractPercentageSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  userRow: users;
}
const projectSchema = Yup.object().shape({
  managerUID: Yup.string().required("*"),
  office: Yup.string().required("*"),
});

function UsersInformation(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState, userRow } = props;
  const [loading, setLoading] = useState(false);
  const uid = currentUser?.id;
  const UID = userRow.id;
  const [partTimeSchedule, setPartTimeSchedule] = useState({
    saturday: { status: false, start: moment(), end: moment() },
    sunday: { status: false, start: moment(), end: moment() },
    monday: { status: false, start: moment(), end: moment() },
    tuesday: { status: false, start: moment(), end: moment() },
    wednesday: { status: false, start: moment(), end: moment() },
    thursday: { status: false, start: moment(), end: moment() },
    friday: { status: false, start: moment(), end: moment() },
  });

  const data = userRow as users;
  const staffFullName = `${data?.firstName} ${data?.lastName}`;
  const formik = useFormik({
    initialValues: {
      pay_no: "EMP",
      fatherName: "",
      motherName: "",
      salary: 0,
      currency: "",
      startingDate: moment(),
      endingDate: moment(),
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      gender: "",
      office: "",
      arabicFullName: "",
      dateOfBirth: moment(),
      placeOfBirth: "",
      permanentAddress: "",
      currentAddress: "",
      phoneNumber: "",
      identityNo: "",
      emergencyContact1: "",
      emergencyContact2: "",
      bankAccountName: "",
      bankFirstName: "",
      bankLastName: "",
      customerNumber: "",
      bankName: "",
      IBAN: "",
      bankAccountNumber: "",
      managerUID: "",
      position: "",
      department: "",
      vacationAnnualScore: 0,
      vacationCompensationScore: 0,
      vacationSickScore: 0,
      vacationDeathScore: 0,
      vacationMarriageScore: 0,
      vacationMaternityScore: 0,
      vacationPaternityScore: 0,
      region: "",
      employmentType: "fullTime",
      idaAllocationPercentage: 0,
      resourceNO: "",
      paymentType: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      const updatedUser = {
        pay_no: values.pay_no,
        id: userRow.id,
        fatherName: values.fatherName,
        motherName: values.motherName,
        salary: values.salary,
        currency: values.currency,
        startingDate: values.startingDate as any,
        endingDate: values.endingDate as any,
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        arabicFullName: values.arabicFullName,
        dateOfBirth: values.dateOfBirth as any,
        placeOfBirth: values.placeOfBirth,
        permanentAddress: values.permanentAddress,
        currentAddress: values.currentAddress,
        phoneNumber: values.phoneNumber,
        identityNo: values.identityNo,
        emergencyContact1: values.emergencyContact1,
        emergencyContact2: values.emergencyContact2,
        bankAccountName: values.bankAccountName,
        bankName: values.bankName,
        IBAN: values.IBAN,
        bankAccountNumber: values.bankAccountNumber,
        bankFirstName: values.bankFirstName,
        bankLastName: values.bankLastName,
        customerNumber: values.customerNumber,
        vacationCompensationScore: values.vacationCompensationScore,
        vacationDeathScore: values.vacationDeathScore,
        vacationMarriageScore: values.vacationMarriageScore,
        vacationMaternityScore: values.vacationMaternityScore,
        vacationPaternityScore: values.vacationPaternityScore,
        managerUID: values.managerUID,
        office: values.office,
        position: values.position,
        department: values.department,
        vacationAnnualScore: values.vacationAnnualScore,
        vacationSickScore: values.vacationSickScore,
        region: values.region,
        idaAllocationPercentage: values.idaAllocationPercentage,
        employmentType: values.employmentType,
        resourceNO: values.resourceNO,
        partTimeSchedule: {
          saturday: {
            ...partTimeSchedule?.saturday,
            start: moment(partTimeSchedule?.saturday?.start),
            end: moment(partTimeSchedule?.saturday?.end),
          },
          sunday: {
            ...partTimeSchedule?.sunday,
            start: moment(partTimeSchedule?.sunday?.start),
            end: moment(partTimeSchedule?.sunday?.end),
          },
          monday: {
            ...partTimeSchedule?.monday,
            start: moment(partTimeSchedule?.monday?.start),
            end: moment(partTimeSchedule?.monday?.end),
          },
          tuesday: {
            ...partTimeSchedule?.tuesday,
            start: moment(partTimeSchedule?.tuesday?.start),
            end: moment(partTimeSchedule?.tuesday?.end),
          },
          wednesday: {
            ...partTimeSchedule?.wednesday,
            start: moment(partTimeSchedule?.wednesday?.start),
            end: moment(partTimeSchedule?.wednesday?.end),
          },
          thursday: {
            ...partTimeSchedule?.thursday,
            start: moment(partTimeSchedule?.thursday?.start),
            end: moment(partTimeSchedule?.thursday.end),
          },
          friday: {
            ...partTimeSchedule?.friday,
            start: moment(partTimeSchedule?.friday?.start),
            end: moment(partTimeSchedule?.friday?.end),
          },
        },
        paymentType: values.paymentType,
      };
      dispatch(editUser(updatedUser as users))
        .then(() => {
          if (data.position != values.position) {
            dispatch(
              addPosition({
                office: values.office ?? "",
                uid: UID as string,
                position: values.position,
                startDate: moment() as any,
                endDate: moment().add(1, "years") as any,
                by: currentUser.id,
              })
            );
          }
        })
        .then(() => {
          if (data.salary != values.salary) {
            dispatch(
              addSalary({
                uid: UID as string,
                salary: values.salary,
                currency: values.currency,
                startDate: moment() as any,
                endDate: moment().add(1, "years") as any,
                createdAt: moment() as any,
                by: currentUser.id as string,
                office: globalUsersData[UID ?? ""]?.office ?? "",
              })
            );
          }
        })
        .then(() => {
          if (data.office != values.office) {
            dispatch(
              addWorkOffice({
                uid: UID as string,
                office: values.office ?? "",
                startDate: moment() as any,
                endDate: moment().add(1, "years") as any,
                createdAt: moment() as any,
                by: uid as string,
              })
            );
          }
        })
        .then(() => {
          if (data.managerUID != values.managerUID) {
            dispatch(
              addSupervisor({
                office: values.office ?? "",
                uid: UID as string,
                supervisorUid: values.managerUID,
                startDate: moment() as any,
                endDate: moment().add(1, "years") as any,
                createdAt: moment() as any,
                by: uid as string,
              })
            );
          }
        })
        .then(() => {
          if (data.paymentType != values.paymentType) {
            dispatch(
              addPaymentType({
                office: values.office ?? "",
                uid: UID as string,
                paymentType: values.paymentType,
                startDate: moment() as any,
                endDate: moment().add(1, "years") as any,
                createdAt: moment() as any,
                by: uid as string,
              })
            );
          }
        })
        .then(() => {
          if (data.idaAllocationPercentage != values.idaAllocationPercentage) {
            dispatch(
              addContractPercentage({
                office: values.office ?? "",
                uid: UID as string,
                contractPercentage: values.idaAllocationPercentage,
                startDate: moment() as any,
                endDate: moment().add(1, "years") as any,
                createdAt: moment() as any,
                by: uid as string,
              })
            );
          }
        })
        .then(() => {
          message.success("Done!");
          console.log("Document successfully written!");
          setModalState(false);
          formik.resetForm();
          setLoading(false);
        });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (userRow && loaded) {
      formik.setValues({
        pay_no: data?.pay_no ?? "",
        fatherName: data.fatherName ?? "",
        motherName: data.motherName ?? "",
        salary: data.salary ?? "",
        currency: data.currency ?? "",
        startingDate: moment(data.startingDate),
        endingDate: moment(data.endingDate),
        email: data.email ?? "",
        password: data.password ?? "",
        firstName: data.firstName ?? "",
        lastName: data.lastName ?? "",
        gender: data.gender ?? "",
        arabicFullName: data.arabicFullName ?? "",
        dateOfBirth: moment(data.dateOfBirth),
        placeOfBirth: data.placeOfBirth ?? "",
        permanentAddress: data.permanentAddress ?? "",
        currentAddress: data.currentAddress ?? "",
        phoneNumber: data.phoneNumber ?? "",
        identityNo: data.identityNo ?? "",
        emergencyContact1: data.emergencyContact1 ?? "",
        emergencyContact2: data.emergencyContact2 ?? "",
        bankAccountName: data.bankAccountName ?? "",
        bankName: data.bankName ?? "",
        IBAN: data.IBAN ?? "",
        bankAccountNumber: data.bankAccountNumber ?? "",
        bankFirstName: data.bankFirstName ?? "",
        bankLastName: data.bankLastName ?? "",
        customerNumber: data.customerNumber ?? "",

        vacationCompensationScore: data.vacationCompensationScore ?? "",
        vacationDeathScore: data.vacationDeathScore ?? "",
        vacationMarriageScore: data.vacationMarriageScore ?? "",
        vacationMaternityScore: data.vacationMaternityScore ?? "",
        vacationPaternityScore: data.vacationPaternityScore ?? "",
        managerUID: data.managerUID ?? "",
        office: data.office ?? "",
        position: data.position ?? "",
        department: data.department ?? "",
        vacationAnnualScore: data.vacationAnnualScore ?? "",
        vacationSickScore: data.vacationSickScore ?? "",
        region: data.region ?? "",
        employmentType: data.employmentType ?? "",
        idaAllocationPercentage: data.idaAllocationPercentage ?? 0,
        resourceNO: data.resourceNO ?? "",
        paymentType: data.paymentType ?? "",
      } as any);
      setPartTimeSchedule(
        data.partTimeSchedule
          ? {
              saturday: {
                ...data.partTimeSchedule.saturday,
                start: moment(data.partTimeSchedule.saturday.start),
                end: moment(data.partTimeSchedule.saturday.end),
              },
              sunday: {
                ...data.partTimeSchedule.sunday,
                start: moment(data.partTimeSchedule.sunday.start),
                end: moment(data.partTimeSchedule.sunday.end),
              },
              monday: {
                ...data.partTimeSchedule.monday,
                start: moment(data.partTimeSchedule.monday.start),
                end: moment(data.partTimeSchedule.monday.end),
              },
              tuesday: {
                ...data.partTimeSchedule.tuesday,
                start: moment(data.partTimeSchedule.tuesday.start),
                end: moment(data.partTimeSchedule.tuesday.end),
              },
              wednesday: {
                ...data.partTimeSchedule.wednesday,
                start: moment(data.partTimeSchedule.wednesday.start),
                end: moment(data.partTimeSchedule.wednesday.end),
              },
              thursday: {
                ...data.partTimeSchedule.thursday,
                start: moment(data.partTimeSchedule.thursday.start),
                end: moment(data.partTimeSchedule.thursday.end),
              },
              friday: {
                ...data.partTimeSchedule.friday,
                start: moment(data.partTimeSchedule.friday.start),
                end: moment(data.partTimeSchedule.friday.end),
              },
            }
          : partTimeSchedule
      );
      setLoaded(false);
    }
  }, [userRow, formik, loaded]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const format = "HH:mm";

  return (
    <Drawer
      title={`(${staffFullName}) profile`}
      placement={"left"}
      width={1000}
      visible={modalState}
      onClose={() => {
        setModalState(false);
        formik.resetForm();
      }}
    >
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.firstName")} span={3}>
                <Input
                  value={formik.values.firstName}
                  onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.lastName")} span={3}>
                <Input
                  value={formik.values.lastName}
                  onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.identityNo")} span={3}>
                <Input
                  type="text"
                  id="identityNo"
                  value={formik.values.identityNo}
                  onChange={(e) => formik.setFieldValue("identityNo", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.arabicFullName")} span={3}>
                <Input
                  type="text"
                  id="arabicFullName"
                  value={formik.values.arabicFullName}
                  onChange={(e) => formik.setFieldValue("arabicFullName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.dateOfBirth")} span={3}>
                <DatePicker
                  value={formik.values.dateOfBirth}
                  format={dateFormat}
                  onChange={(e: any) => {
                    formik.setFieldValue("dateOfBirth", e);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.placeOfBirth")} span={3}>
                <Input
                  type="text"
                  id="placeOfBirth"
                  value={formik.values.placeOfBirth}
                  onChange={(e) => formik.setFieldValue("placeOfBirth", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.permanentAddress")} span={6}>
                <Input
                  type="text"
                  id="permanentAddress"
                  value={formik.values.permanentAddress}
                  onChange={(e) => formik.setFieldValue("permanentAddress", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.currentAddress")} span={6}>
                <Input
                  type="text"
                  id="currentAddress"
                  value={formik.values.currentAddress}
                  onChange={(e) => formik.setFieldValue("currentAddress", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.gender")} span={3}>
                <Select
                  id="gender"
                  showSearch
                  value={formik.values.gender}
                  onChange={(e) => {
                    formik.setFieldValue("gender", e);
                  }}
                >
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.fatherName")} span={3}>
                <Input
                  type="text"
                  id="fatherName"
                  value={formik.values.fatherName}
                  onChange={(e) => formik.setFieldValue("fatherName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.motherName")} span={3}>
                <Input
                  type="text"
                  id="motherName"
                  value={formik.values.motherName}
                  onChange={(e) => formik.setFieldValue("motherName", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.phoneNumber")} span={3}>
                <Input
                  type="text"
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.emergencyContact1")} span={3}>
                <Input
                  type="text"
                  id="emergencyContact1"
                  value={formik.values.emergencyContact1}
                  onChange={(e) => formik.setFieldValue("emergencyContact1", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.emergencyContact2")} span={3}>
                <Input
                  type="text"
                  id="emergencyContact2"
                  value={formik.values.emergencyContact2}
                  onChange={(e) => formik.setFieldValue("emergencyContact2", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.startingDate")} span={3}>
                <DatePicker
                  value={formik.values.startingDate}
                  format={dateFormat}
                  onChange={(e: any) => {
                    formik.setFieldValue("startingDate", e);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.endingDate")} span={3}>
                <DatePicker
                  value={formik.values.endingDate}
                  format={dateFormat}
                  onChange={(e: any) => {
                    formik.setFieldValue("endingDate", e);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.salary")} span={3}>
                <InputNumber
                  // disabled
                  min={0}
                  value={formik.values.salary}
                  onChange={(e) => formik.setFieldValue("salary", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.currency")} span={3}>
                <Select
                  showSearch
                  value={formik.values.currency}
                  onChange={(e) => {
                    formik.setFieldValue("currency", e);
                  }}
                >
                  <Option value={"USD"}>{"USD"}</Option>
                  <Option value={"EURO"}>{"EURO"}</Option>
                  <Option value={"TL"}>{"TL"}</Option>
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.bankAccountName")} span={3}>
                <Input
                  type="text"
                  id="bankAccountName"
                  value={formik.values.bankAccountName}
                  onChange={(e) => formik.setFieldValue("bankAccountName", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.bankFirstName")} span={3}>
                <Input
                  type="text"
                  id="bankFirstName"
                  value={formik.values.bankFirstName}
                  onChange={(e) => formik.setFieldValue("bankFirstName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.bankLastName")} span={3}>
                <Input
                  type="text"
                  id="bankLastName"
                  value={formik.values.bankLastName}
                  onChange={(e) => formik.setFieldValue("bankLastName", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.bankName")} span={3}>
                <Input
                  type="text"
                  id="bankName"
                  value={formik.values.bankName}
                  onChange={(e) => formik.setFieldValue("bankName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.IBAN")} span={3}>
                <Input
                  type="text"
                  id="IBAN"
                  value={formik.values.IBAN}
                  onChange={(e) => formik.setFieldValue("IBAN", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.bankAccountNumber")} span={3}>
                <Input
                  type="text"
                  id="bankAccountNumber"
                  value={formik.values.bankAccountNumber}
                  onChange={(e) => formik.setFieldValue("bankAccountNumber", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.customerNumber")} span={3}>
                <Input
                  type="text"
                  id="customerNumber"
                  value={formik.values.customerNumber}
                  onChange={(e) => formik.setFieldValue("customerNumber", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.supervisor")} span={3}>
                <Select
                  filterOption={(inputValue, option) => {
                    return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                  }}
                  // disabled
                  showSearch
                  value={formik.values.managerUID}
                  onChange={(e) => {
                    formik.setFieldValue("managerUID", e);
                  }}
                >
                  {USERS_ID_FULL_NAME?.map((d) => {
                    return <Option value={d.uid}>{d.fullName}</Option>;
                  })}
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.location")} span={3}>
                <Select
                  // disabled
                  filterOption={(inputValue, option) => {
                    return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                  }}
                  showSearch
                  value={formik.values.office}
                  onChange={(e) => {
                    formik.setFieldValue("office", e);
                    const officeData = globalOfficesData[e ?? ""];
                    setPartTimeSchedule({
                      saturday: {
                        status: false,
                        start: moment(officeData.workStartTime),
                        end: moment(officeData.workEndTime),
                      },
                      sunday: {
                        status: false,
                        start: moment(officeData.workStartTime),
                        end: moment(officeData.workEndTime),
                      },
                      monday: {
                        status: false,
                        start: moment(officeData.workStartTime),
                        end: moment(officeData.workEndTime),
                      },
                      tuesday: {
                        status: false,
                        start: moment(officeData.workStartTime),
                        end: moment(officeData.workEndTime),
                      },
                      wednesday: {
                        status: false,
                        start: moment(officeData.workStartTime),
                        end: moment(officeData.workEndTime),
                      },
                      thursday: {
                        status: false,
                        start: moment(officeData.workStartTime),
                        end: moment(officeData.workEndTime),
                      },
                      friday: {
                        status: false,
                        start: moment(officeData.workStartTime),
                        end: moment(officeData.workEndTime),
                      },
                    });
                  }}
                >
                  {ALL_OFFICES_ID_NAME?.map((d) => {
                    return <Option value={d.officeId}>{d.officeName}</Option>;
                  })}
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.position")} span={3}>
                <Input
                  // disabled
                  value={formik.values.position}
                  onChange={(e) => formik.setFieldValue("position", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.department")} span={3}>
                <Input
                  value={formik.values.department}
                  onChange={(e) => formik.setFieldValue("department", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.vacationAnnualScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationAnnualScore}
                  onChange={(e) => formik.setFieldValue("vacationAnnualScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationSickScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationSickScore}
                  onChange={(e) => formik.setFieldValue("vacationSickScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationCompensationScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationCompensationScore}
                  onChange={(e) => formik.setFieldValue("vacationCompensationScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationDeathScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationDeathScore}
                  onChange={(e) => formik.setFieldValue("vacationDeathScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationMarriageScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationMarriageScore}
                  onChange={(e) => formik.setFieldValue("vacationMarriageScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationMaternityScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationMaternityScore}
                  onChange={(e) => formik.setFieldValue("vacationMaternityScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationPaternityScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationPaternityScore}
                  onChange={(e) => formik.setFieldValue("vacationPaternityScore", e)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.region")} span={3}>
                <Form.Item validateStatus={formik.errors.region ? "error" : ""}>
                  <Select
                    disabled
                    id="region"
                    showSearch
                    value={formik.values.region}
                    onChange={(e) => {
                      formik.setFieldValue("region", e);
                    }}
                  >
                    <Option value="syria">Syria</Option>
                    <Option value="turkey">Turkey</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.paymentType")} span={3}>
                <Form.Item validateStatus={formik.errors.paymentType ? "error" : ""}>
                  <Select
                    // disabled
                    id="region"
                    showSearch
                    value={formik.values.paymentType}
                    onChange={(e) => {
                      formik.setFieldValue("paymentType", e);
                    }}
                  >
                    <Option value="cash">Cash</Option>
                    <Option value="transfer">Transfer</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.resourceNO")} span={3}>
                <Input
                  disabled
                  value={formik.values.resourceNO}
                  onChange={(e) => formik.setFieldValue("resourceNO", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.email")} span={3}>
                <Input value={formik.values.email} onChange={(e) => formik.setFieldValue("email", e.target.value)} />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.idaAllocationPercentage")} span={3}>
                <Form.Item validateStatus={formik.errors.idaAllocationPercentage ? "error" : ""}>
                  <InputNumber
                    // disabled={true}
                    min={0}
                    value={formik.values.idaAllocationPercentage}
                    onChange={(e) => formik.setFieldValue("idaAllocationPercentage", e)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={"Payroll-NO"} span={3}>
                <Form.Item validateStatus={formik.errors.pay_no ? "error" : ""}>
                  <Input
                    value={formik.values.pay_no}
                    onChange={(e) => formik.setFieldValue("pay_no", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label={t("general.employmentType")} span={6}>
                <Form.Item validateStatus={formik.errors.employmentType ? "error" : ""}>
                  <Radio.Group
                    onChange={(e) => {
                      formik.setFieldValue("employmentType", e.target.value);
                    }}
                    value={formik.values.employmentType}
                  >
                    <Radio value={"fullTime"}>Full-Time</Radio>
                    <Radio value={"partTime"}>Part-Time</Radio>
                    <Radio value={"shift"}>Shift</Radio>
                  </Radio.Group>
                </Form.Item>
              </Descriptions.Item>
              {formik.values.employmentType == "partTime" ? (
                <Descriptions.Item label={t("general.partTimeSchedule")} span={6}>
                  <Form.Item>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.saturday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            saturday: { ...partTimeSchedule.saturday, status: e.target.checked },
                          });
                        }}
                      >
                        Saturday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowEmpty={false}
                          allowClear={false}
                          format={format}
                          onChange={(e) => {
                            if (e != null) {
                              setPartTimeSchedule({
                                ...partTimeSchedule,
                                saturday: { ...partTimeSchedule.saturday, start: moment(e) as any },
                              });
                            }
                          }}
                          value={partTimeSchedule?.saturday?.start}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowEmpty={false}
                        allowClear={false}
                        format={format}
                        onChange={(e) => {
                          if (e != null) {
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              saturday: { ...partTimeSchedule.saturday, end: moment(e) as any },
                            });
                          }
                        }}
                        value={moment(partTimeSchedule.saturday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.sunday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            sunday: { ...partTimeSchedule.sunday, status: e.target.checked },
                          });
                        }}
                      >
                        Sunday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) => {
                            if (e != null) {
                              setPartTimeSchedule({
                                ...partTimeSchedule,
                                sunday: { ...partTimeSchedule.sunday, start: moment(e) as any },
                              });
                            }
                          }}
                          value={moment(partTimeSchedule.sunday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) => {
                          if (e != null) {
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              sunday: { ...partTimeSchedule.sunday, end: moment(e) as any },
                            });
                          }
                        }}
                        value={moment(partTimeSchedule.sunday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.monday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            monday: { ...partTimeSchedule.monday, status: e.target.checked },
                          });
                        }}
                      >
                        Monday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) => {
                            if (e != null) {
                              setPartTimeSchedule({
                                ...partTimeSchedule,
                                monday: { ...partTimeSchedule.monday, start: moment(e) as any },
                              });
                            }
                          }}
                          value={moment(partTimeSchedule.monday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) => {
                          if (e != null) {
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              monday: { ...partTimeSchedule.monday, end: moment(e) as any },
                            });
                          }
                        }}
                        value={moment(partTimeSchedule.monday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.tuesday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            tuesday: { ...partTimeSchedule.tuesday, status: e.target.checked },
                          });
                        }}
                      >
                        Tuesday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) => {
                            if (e != null) {
                              setPartTimeSchedule({
                                ...partTimeSchedule,
                                tuesday: { ...partTimeSchedule.tuesday, start: moment(e) as any },
                              });
                            }
                          }}
                          value={moment(partTimeSchedule.tuesday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) => {
                          if (e != null) {
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              tuesday: { ...partTimeSchedule.tuesday, end: moment(e) as any },
                            });
                          }
                        }}
                        value={moment(partTimeSchedule.tuesday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.wednesday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            wednesday: { ...partTimeSchedule.wednesday, status: e.target.checked },
                          });
                        }}
                      >
                        Wednesday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) => {
                            if (e != null) {
                              setPartTimeSchedule({
                                ...partTimeSchedule,
                                wednesday: { ...partTimeSchedule.wednesday, start: moment(e) as any },
                              });
                            }
                          }}
                          value={moment(partTimeSchedule.wednesday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) => {
                          if (e != null) {
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              wednesday: { ...partTimeSchedule.wednesday, end: moment(e) as any },
                            });
                          }
                        }}
                        value={moment(partTimeSchedule.wednesday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.thursday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            thursday: { ...partTimeSchedule.thursday, status: e.target.checked },
                          });
                        }}
                      >
                        Thursday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) => {
                            if (e != null) {
                              setPartTimeSchedule({
                                ...partTimeSchedule,
                                thursday: { ...partTimeSchedule.thursday, start: moment(e) as any },
                              });
                            }
                          }}
                          value={moment(partTimeSchedule.thursday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            thursday: { ...partTimeSchedule.thursday, end: moment(e) as any },
                          })
                        }
                        value={moment(partTimeSchedule.thursday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.friday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            friday: { ...partTimeSchedule.friday, status: e.target.checked },
                          });
                        }}
                      >
                        Friday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) => {
                            if (e != null) {
                              setPartTimeSchedule({
                                ...partTimeSchedule,
                                friday: { ...partTimeSchedule.friday, start: moment(e) as any },
                              });
                            }
                          }}
                          value={moment(partTimeSchedule.friday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) => {
                          if (e != null) {
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              friday: { ...partTimeSchedule.friday, end: moment(e) as any },
                            });
                          }
                        }}
                        value={moment(partTimeSchedule.friday.end)}
                      />
                    </Row>
                  </Form.Item>
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default UsersInformation;
