import React, { useState, useRef, useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Select, DatePicker, Checkbox, Icon } from "antd";
const { Option } = Select;
import { sumBy, uniq } from "lodash";
import moment from "moment";
const { MonthPicker } = DatePicker;
import DevTable from "../../helpers/devex-table";
import { currentSalary } from "../../db/currentSalary";
import { currentP } from "../../db/currentPosition";
import PayrollToPrint from "./payrollToPrint";
import { globalOfficesData } from "../../global/officeList";
import _ from "lodash";
import { currentContractPercentage } from "../../db/currentContractPercentage";
import { globalUsersData } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchAllocationsByProjectIdAndDate } from "../../features/allocations/allocationsSlice";
import { globalProjectsData } from "../../global/projectList";
import { globalMissionsData } from "../../global/missionList";
import { globalBudgetLinesData } from "../../global/budgetLineList";
import { fetchDeductionsByMonth } from "../../features/deductions/deductionsSlice";
import { fetchDelaysByMonth } from "../../features/delays/delaysSlice";
import { fetchAccessByOperationDate } from "../../features/access/accessSlice";
import { fetchVacationsByDate } from "../../features/vacations/vacationsSlice";
import { setFilterValue } from "../../features/filters/filtersSlice";

export default function PayrollByProject() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const {
    data: vacationsData,
    status: vacationsStatus,
    error: vacationsError,
  } = useAppSelector((state) => state.vacations);
  const {
    data: allocationsData,
    status: allocationsStatus,
    error: allocationsError,
  } = useAppSelector((store) => store.allocations);
  const { data: delaysData, status: delaysStatus, error: delaysError } = useAppSelector((store) => store.delays);
  const {
    data: deductionsData,
    status: deductionsStatus,
    error: deductionsError,
  } = useAppSelector((store) => store.deductions);
  const { data: accessData, status: accessStatus, error: accessError } = useAppSelector((store) => store.access);

  const dispatch = useAppDispatch();
  const [month, setMonth] = useState(moment());
  const [projectId, setProjectId] = useState(filterValues["projectId"] ?? "Select a project..");
  const [filteredPosition, setFilteredPosition] = useState([] as string[]);
  const [missionId, setMissionId] = useState("all");
  const [fingerPrintActive, setFingerPrintActive] = useState(false);
  const [defaultColumnWidths] = useState([
    { columnName: "pay_no", width: 150 },

    { columnName: "userId", width: 150 },
    { columnName: "resourceNO", width: 150 },
    { columnName: "firstName", width: 150 },
    { columnName: "lastName", width: 150 },
    { columnName: "position", width: 150 },
    { columnName: "workingDayInMonth", width: 150 },
    { columnName: "deduction", width: 150 },
    { columnName: "leaveWithoutPay", width: 250 },
    { columnName: "absentDays", width: 150 },
    { columnName: "daysWorked", width: 150 },
    { columnName: "monthlySalary", width: 250 },
    { columnName: "currency", width: 200 },
    { columnName: "numberOfHoursLate", width: 250 },
    { columnName: "hoursLateDeduction", width: 250 },
    { columnName: "totalSalaryForCurrentMonth", width: 250 },
    { columnName: "difference", width: 150 },
    { columnName: "donor", width: 150 },
    { columnName: "project", width: 150 },
    { columnName: "budgetLineId", width: 150 },
    { columnName: "percentage", width: 150 },
    { columnName: "allocatedSalary", width: 200 },
    { columnName: "idaContractPercentage", width: 200 },
    { columnName: "contractSalary", width: 200 },
  ]);
  const print1CbRef = useRef<() => void>();

  const printPayroll = useCallback(() => {
    setTimeout(() => {
      print1CbRef.current();
    }, 1500);
  }, []);

  useEffect(() => {
    dispatch(
      fetchVacationsByDate({
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
        byField: "vacationStartOn",
      })
    );
    dispatch(
      fetchAllocationsByProjectIdAndDate({
        projectId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
      })
    );
    dispatch(fetchDeductionsByMonth(month.format("MM-YYYY").toString()));

    dispatch(fetchDelaysByMonth({ month: month.format("MMM.YYYY").toString() }));
    dispatch(
      fetchAccessByOperationDate({
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
      })
    );
  }, [dispatch, month, projectId]);

  if (
    vacationsStatus == "loading" ||
    allocationsStatus == "loading" ||
    delaysStatus == "loading" ||
    accessStatus == "loading" ||
    deductionsStatus == "loading"
  )
    return <Spin />;

  const missions = Object.values(globalMissionsData);
  const delays = delaysData
    .map((d) => {
      return { ...d, id: d.id, uid: d.userUID, min: d.min, status: d.status };
    })
    .filter((d) => d.status != "disabled");

  const VacationDates = vacationsData
    .filter((vacation) => vacation.status != "Rejected" && vacation.status != "Canceled")
    .map((d) => {
      return {
        ...d,
        employeeUID: d.employeeUID,
        start: d.vacationStartOn,
        end: d.vacationEndOn,
        duration: d.vacationPeriodDays,
        type: d.vacationType,
        halfDay: d.halfDay,
        day: d.halfDay ? 0.5 : 1,
      };
    });

  function daysInMonth(m: any) {
    var count = moment().year(m.year()).month(m.month()).daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(moment().year(m.year()).month(m.month()).date(i));
    }
    return days;
  }
  var days = daysInMonth(month);

  const deductionData = deductionsData;

  const projectList = Object.values(globalProjectsData).map((p) => {
    return { id: p.id, projectCode: p?.projectCode, donor: p?.donor };
  });

  const users = allocationsData
    .filter((al) => globalUsersData[al.uid])
    .map((al, index) => {
      const userData = globalUsersData[al.uid ?? ""];
      return {
        ...userData,
        ...al,
        id: al.id,
        userId: userData.id,
        index: index + 1,
      };
    });

  const userAllocation = users;

  const access = accessData;

  const absentDaysArr = userAllocation.map((u: any) => {
    const absentDays = days
      .map((day) => {
        let absent = false;

        const accessInDay = access
          .filter((a) => a.uid == u.userId)
          .filter((a) => {
            return moment(a.date).format("DD-MM-YYYY").toString() == moment(day).format("DD-MM-YYYY").toString();
          }).length;

        const vacationsFiltered = VacationDates.filter((v) => v.employeeUID == u.userId).filter((vacation: any) => {
          const vacationStartOn = moment(vacation?.vacationStartOn).startOf("day");
          const vacationEndOn = moment(vacation?.vacationEndOn).endOf("day");

          return moment(day).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
        }).length;

        if (vacationsFiltered == 0 && accessInDay == 0) {
          absent = true;
        }

        return { absent, day };
      })
      .filter((ab) => ab.absent == true).length;

    return { uid: u.userId, absentDays };
  });

  const data = userAllocation
    .map((row: any) => {
      const id = row.id;
      const missionId = globalOfficesData[row.office ?? ""]?.missionId;
      const position = currentP(row.userId, month) ?? row.position;

      const allocationStartDate = row.startDate;
      const allocationEndDate = row.endDate;

      const selectedMonth = month;
      const startDate = moment(allocationStartDate);
      const endDate = moment(allocationEndDate);

      const selectedMonthDays = _.range(
        startDate.month() === selectedMonth.month() && startDate.year() === selectedMonth.year() ? startDate.date() : 1,
        endDate.month() === selectedMonth.month() && endDate.year() === selectedMonth.year()
          ? endDate.date() + 1
          : moment(startDate).year(selectedMonth.year()).month(selectedMonth.month()).endOf("month").date() + 1
      ).length;

      const monthDays = days.length;
      const workingDayInMonth = selectedMonthDays;

      const deduction = deductionData.find((d) => d.uid == row.userId)?.percentage ?? 0;

      const unpaidVacationMap = days.map((day) => {
        const unpaidLeaveFiltered = VacationDates.filter(
          (v) => v.employeeUID == row.userId && v.type == "Unpaid Leave"
        ).filter((vacation: any) => {
          const vacationStartOn = moment(vacation?.vacationStartOn).startOf("day");
          const vacationEndOn = moment(vacation?.vacationEndOn).endOf("day");
          return moment(day).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
        });

        const sum = _.sumBy(unpaidLeaveFiltered, "day");
        return sum;
      });
      const leaveWithoutPay = _.sumBy(unpaidVacationMap);

      const absentDays = (
        fingerPrintActive ? absentDaysArr.find((ab) => ab.uid == row.userId)?.absentDays : 0
      ) as number;
      const daysWorked = workingDayInMonth - absentDays * 2 - leaveWithoutPay;

      const monthlySalary = currentSalary(row.userId, month).salary ?? row.salary;

      const idaContractPercentage =
        currentContractPercentage(row.userId, month) ?? globalUsersData[row.userId]?.idaAllocationPercentage;

      const contractSalary = (monthlySalary * idaContractPercentage) / 100;

      const delaysMin = sumBy(
        delays.filter((d) => d.uid == row.userId),
        (d) => d.min
      );

      const delaysHours = delaysMin >= 120 ? delaysMin / 60 : 0;
      const numberOfHoursLate = delaysHours.toFixed(2);

      const salaryInHour = contractSalary / monthDays / 8;
      const deductionByHour = salaryInHour * (delaysHours * 2);
      const hoursLateDeduction = deductionByHour.toFixed(3);
      ///
      const salaryInDay = contractSalary / monthDays;
      const deductionPercentage = deductionData.find((d) => d.uid == row.userId)?.percentage ?? 0;
      const salaryAfterDeduction =
        salaryInDay * daysWorked - deductionByHour - (deductionPercentage * contractSalary * row.percentage) / 10000;
      const totalSalaryForCurrentMonth = parseFloat(salaryAfterDeduction.toFixed(2));
      ///
      const differenceNumber = contractSalary - salaryAfterDeduction;
      const difference = parseFloat(differenceNumber.toFixed(2));
      ///
      const donor = globalProjectsData[row.projectId ?? ""]?.donor;
      const project = globalProjectsData[row.projectId ?? ""]?.projectCode;
      const budgetLineId = globalBudgetLinesData[row.budgetLineId ?? ""]?.budgetLine;
      const percentage = row.percentage + " " + "%";
      const allocatedSalaryNumber = (row.percentage * salaryAfterDeduction) / 100;
      const allocatedSalary = parseFloat(allocatedSalaryNumber.toFixed(2));
      const currency = currentSalary(row.userId, month).currency ?? row?.currency;

      return {
        ...row,
        position,
        workingDayInMonth,
        deduction,
        leaveWithoutPay,
        absentDays,
        daysWorked,
        monthlySalary,
        currency,
        numberOfHoursLate,
        hoursLateDeduction,
        totalSalaryForCurrentMonth,
        salaryAfterDeduction,
        difference,
        differenceNumber,
        donor,
        project,
        budgetLineId,
        percentage,
        missionId,
        allocatedSalary,
        allocatedSalaryNumber,
        contractSalary,
        idaContractPercentage,
        id,
      };
    })
    .filter((row) => {
      if (missionId == "all") {
        return true;
      } else {
        return row.missionId == missionId;
      }
    });

  const positions = uniq(data.map((d) => d.position));
  const columns = [
    {
      name: "pay_no",
      title: "Pay-NO",
      getCellValue: (row: any) => {
        return parseInt(row.pay_no);
      },
    },
    { name: "resourceNO", title: "Resource NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    {
      name: "position",
      title: "Position",
    },
    {
      name: "workingDayInMonth",
      title: "Work days in a month",
    },
    {
      name: "deduction",
      title: "Deduction",
    },
    {
      name: "leaveWithoutPay",
      title: "Leave Without Pay",
    },
    {
      name: "absentDays",
      title: "Absent Days",
    },
    {
      name: "daysWorked",
      title: "Days worked",
    },
    {
      name: "monthlySalary",
      title: "Monthly salary",
    },
    {
      name: "currency",
      title: "Currency",
    },
    {
      name: "idaContractPercentage",
      title: "Contract Percentage",
    },
    {
      name: "contractSalary",
      title: "Contract Salary",
    },
    {
      name: "numberOfHoursLate",
      title: "Number of Hours Late",
    },
    {
      name: "hoursLateDeduction",
      title: "Hours Late Deduction",
    },
    {
      name: "totalSalaryForCurrentMonth",
      title: "Total Salary for current month",
    },
    {
      name: "difference",
      title: "Difference",
    },
    {
      name: "donor",
      title: "Donor",
    },
    {
      name: "project",
      title: "Project",
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
    },
    {
      name: "percentage",
      title: "Percentage",
    },
    {
      name: "allocatedSalary",
      title: "Allocated Salary",
    },
  ];

  const tableData = data.filter((d: any) => {
    if (filteredPosition.length == 0) {
      return true;
    } else {
      if (filteredPosition.includes(d.position)) {
        return true;
      } else {
        return false;
      }
    }
  });
  return vacationsError || allocationsError || delaysError || accessError || deductionsError ? (
    <p>
      {vacationsError}
      {allocationsError}
      {delaysError}
      {accessError}
      {deductionsError}
    </p>
  ) : (
    <div>
      <MonthPicker
        size="default"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for project!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={projectId}
        onChange={(e) => {
          setProjectId(e);
          dispatch(setFilterValue({ key: "projectId", value: e }));
        }}
      >
        {projectList.map((d) => {
          return <Option value={d.id}>{d.projectCode}</Option>;
        })}
      </Select>
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Mission!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        value={missionId}
        onChange={(e) => {
          setMissionId(e);
        }}
      >
        {[...missions, { id: "all", missionName: "All" }].map((d) => {
          return <Option value={d.id}>{d.missionName}</Option>;
        })}
      </Select>
      <Select
        placeholder="Filter for Positions!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        mode="multiple"
        showSearch
        value={filteredPosition}
        onChange={(e: any) => {
          setFilteredPosition(e);
        }}
      >
        {positions?.map((d) => {
          return (
            <Option key={d} value={d}>
              {d}
            </Option>
          );
        })}
      </Select>
      <div>
        <Checkbox
          checked={fingerPrintActive}
          onChange={(e) => {
            setFingerPrintActive(e.target.checked);
          }}
        >
          Finger-print Activation
        </Checkbox>

        <Button
          icon="print"
          type="primary"
          onClick={() => printPayroll()}
          style={{ marginBottom: "1%", marginRight: "1%" }}
        >
          <Icon type="print" />
          Print Payroll
        </Button>
      </div>
      <Paper>
        <DevTable
          data={tableData}
          columns={columns}
          defaultHiddenColumnNames={[]}
          defaultColumnWidths={defaultColumnWidths}
          tableName={`Payroll-List`}
          selected={false}
          typicalTable={false}
          columnsExport={columns}
          summaryColumn={true}
          totalItems={[
            { columnName: "allocatedSalary", type: "sum" },
            { columnName: "difference", type: "sum" },
            { columnName: "totalSalaryForCurrentMonth", type: "sum" },
          ]}
        />
      </Paper>
      <div style={{ display: "none" }}>
        <PayrollToPrint
          key={1}
          data={tableData.filter((row) => {
            return missionId == "all" ? true : row.missionId == missionId;
          })}
          printFnRef={print1CbRef as any}
          location={globalMissionsData[missionId ?? ""]?.missionName as string}
          month={month}
          days={days}
        />
      </div>
    </div>
  );
}
