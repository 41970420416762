import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, message, Select, Input } from "antd";
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
const { MonthPicker } = DatePicker;
import { useTranslation } from "react-i18next";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { shift } from "../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editShift } from "../../features/shifts/shfitsSlice";

interface Props {
  shift: shift;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function EditShift(props: Props) {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);

  const { t } = useTranslation();
  const { modalState, setModalState, shift } = props;
  const [loading, setLoading] = useState(false);

  const uid = currentUser.id ?? "";

  const shiftData = shift;

  const formik = useFormik({
    initialValues: {
      uid: "",
      by: "",
      month: moment(),
      type: 8,
      shiftDates: [],
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          editShift({
            id: shift.id,
            createdAt: moment() as any,
            uid: values.uid,
            by: uid,
            type: values.type as any,
            shiftDates: handleShiftDateChange(values.shiftDates) ?? [],
            OriginalShiftDates: values.shiftDates ?? [],
            month: moment(values.month).format("MM-YYYY").toString(),
            office: globalUsersData[values.uid].office ?? "",
          })
        ).unwrap();
        message.success("Shift updated successfully");
        setModalState(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (shiftData && loaded) {
      formik.setValues({
        uid: shiftData.uid,
        by: shiftData.by,
        type: shiftData.type,
        shiftDates: shiftData.OriginalShiftDates ?? [],
        month: moment(shiftData.month, "MM.YYYY"),
        office: globalUsersData[shiftData.uid].office ?? "",
      } as any);
      setLoaded(false);
    }
  }, [shiftData, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };

  function daysInMonth(m: any) {
    var count = moment().year(m.year()).month(m.month()).daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(moment().year(m.year()).month(m.month()).date(i));
    }
    return days;
  }

  var days = daysInMonth(formik.values.month)?.map((day) => day.format("DD.MM.YYYY").toString());

  const handleShiftDateChange = (selectedDates: any) => {
    const updatedShiftDates = [...selectedDates]; // Create a copy of the selected dates array

    if (
      formik.values.type === 24 ||
      formik.values.type == 16 ||
      formik.values.type == 17.5 ||
      formik.values.type == 13 ||
      formik.values.type == 30.5
    ) {
      // Iterate over each selected date
      updatedShiftDates.forEach((date: any) => {
        const currentDate = moment(date, "DD.MM.YYYY");
        const nextDay = currentDate.clone().add(1, "days").format("DD.MM.YYYY");

        // Check if the next day is not already present in the selected dates array
        if (!updatedShiftDates.includes(nextDay)) {
          updatedShiftDates.push(nextDay); // Add the next day to the array
        }
      });
    }

    return updatedShiftDates;
  };

  return (
    <Drawer
      title={t("general.addNewShift")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.month")}>
          <MonthPicker
            style={{ margin: "1%" }}
            placeholder="Select month"
            value={moment(formik.values.month)}
            format={"MM-YYYY"}
            onChange={(e) => formik.setFieldValue("month", e)}
          />
        </Form.Item>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.shiftsType")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.type}
            onChange={(e) => {
              formik.setFieldValue("type", e);
            }}
          >
            <Option value={6.5}>
              {"8:30 => 15:00 - 6.5h"} {" - "} {"د"}
            </Option>
            <Option value={8}>
              {"8:30 => 16:30 - 8h"} {" - "} {"ص"}
            </Option>
            <Option value={8.5}>
              {"12:00 => 08:30 - 8.5h"} {" - "} {"8.5 Morning"}
            </Option>
            <Option value={15}>
              {"12:00 => 15:00 - 15h"} {" - "} {"15 Evening"}
            </Option>
            <Option value={16}>
              {"16:30 => 8:30 - 16h"} {" - "} {"ل"}
            </Option>
            <Option value={17.5}>
              {"15:00 => 8:30 - 17.5h"} {" - "} {"ل"}
            </Option>
            <Option value={24}>
              {"8:30 => 8:30 - 24h"} {" - "} {"م"}
            </Option>
            <Option value={12}>
              {"8:30 => 20:30 - 12h"} {" - "} {"12M"}
            </Option>
            <Option value={13}>
              {"20:30 => 8:30 - 12h"} {" - "} {"12E"}
            </Option>
            <Option value={30.5}>
              {"8:30 => 15:00 - 30.5h"} {" - "} {"م+د"}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item label={t("general.shiftsDates")}>
          <Select
            mode="multiple"
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.shiftDates}
            onChange={(e) => {
              formik.setFieldValue("shiftDates", e);
              console.log(e);
            }}
          >
            {days?.map((d) => {
              const dayNumber = moment(d, "DD.MM.YYYY").format("DD");

              return (
                <Option key={d} value={d}>
                  {dayNumber}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditShift;
