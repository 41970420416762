import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Spin, Button, Select, Checkbox } from "antd";
const { MonthPicker } = DatePicker;
import { users } from "../../db/interfaces";
import printJS from "print-js";
import _, { orderBy } from "lodash";
const { Option } = Select;
import TimeSheetComponent from "./timeSheetComponent";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchVacationsByOfficeByDate } from "../../features/vacations/vacationsSlice";
import { fetchShiftsByOfficeAndMonth } from "../../features/shifts/shfitsSlice";
import { fetchDelaysByOfficeAndMonth } from "../../features/delays/delaysSlice";
import { fetchAllocationsByOfficeIdAndDate } from "../../features/allocations/allocationsSlice";
import { OFFICES_ID_NAME } from "../../global/officeList";
import { setFilterValue } from "../../features/filters/filtersSlice";
import { USERS_ID_FULL_NAME } from "../../global/usersList";
import { fetchUsersByOfficeId } from "../../features/users/usersSlice";

export default function TimeSheetByOffice() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { currentUser } = useAppSelector((state) => state.auth);
  const {
    data: vacationsData,
    status: vacationsStatus,
    error: vacationsError,
  } = useAppSelector((state) => state.vacations);
  const {
    data: allocationsData,
    status: allocationsStatus,
    error: allocationsError,
  } = useAppSelector((store) => store.allocations);
  const { data: delaysData, status: delaysStatus, error: delaysError } = useAppSelector((store) => store.delays);
  const { data: shiftsData, status: shiftsStatus, error: shiftsError } = useAppSelector((store) => store.shifts);
  const { data: usersByOffice, status: usersStatus, error: usersError } = useAppSelector((state) => state.users);

  const dispatch = useAppDispatch();

  const [activeDelay, setActiveDelay] = useState(true);
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");
  const [month, setMonth] = useState(moment().startOf("month").startOf("day"));
  const [uid, setUid] = useState("Select an employee..");

  useEffect(() => {
    dispatch(fetchUsersByOfficeId(officeId));
    dispatch(
      fetchVacationsByOfficeByDate({
        officeId: officeId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
        byField: "vacationStartOn",
      })
    );

    dispatch(
      fetchAllocationsByOfficeIdAndDate({
        officeId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
      })
    );
    dispatch(fetchDelaysByOfficeAndMonth({ officeId: officeId, month: month.format("MMM.YYYY").toString() }));
    dispatch(fetchShiftsByOfficeAndMonth({ officeId: officeId, month: month.format("MM-YYYY").toString() }));
  }, [dispatch, month, officeId]);

  if (
    vacationsStatus == "loading" ||
    allocationsStatus == "loading" ||
    delaysStatus == "loading" ||
    shiftsStatus == "loading" ||
    usersStatus == "loading"
  )
    return <Spin />;

  const print = async () => {
    await printJS({
      printable: "timeSheet2",
      type: "html",
      targetStyles: ["*"],
      scanStyles: true,
      font_size: "15",
      showModal: true,
    });
  };

  return vacationsError || allocationsError || delaysError || shiftsError || usersError ? (
    <p>
      {vacationsError}
      {allocationsError}
      {delaysError}
      {shiftsError}
      {usersError}
    </p>
  ) : (
    <div>
      <MonthPicker
        allowClear={false}
        size="default"
        style={{ marginBottom: "1%", marginRight: "0.5%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />

      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        style={{ width: "25%", marginBottom: "1%", marginRight: "0.5%" }}
        allowClear
        showSearch
        value={uid}
        onChange={(e) => {
          if (e == undefined) {
            setUid("Select an employee..");
          } else setUid(e);
        }}
      >
        {USERS_ID_FULL_NAME?.map((d) => {
          return <Option value={d.uid}>{d.fullName}</Option>;
        })}
      </Select>

      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", marginBottom: "1%", marginRight: "0.5%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>
      {currentUser?.fullControlOffices?.length != 0 ? (
        <Checkbox
          style={{ marginLeft: "1%" }}
          checked={activeDelay}
          value={activeDelay}
          onChange={(e) => {
            setActiveDelay(!activeDelay);
          }}
        >
          Active
        </Checkbox>
      ) : null}
      <Button
        style={{ marginLeft: "1%" }}
        type="primary"
        onClick={async () => {
          print();
        }}
        htmlType="submit"
      >
        Print
      </Button>

      <div id="timeSheet2">
        {orderBy(usersByOffice, (x) => parseInt(x.pay_no ?? "emp"))
          .filter((user) => user.status != "disable")
          .filter((user) => (uid == "Select an employee.." || uid == "all" ? true : user.id == uid))
          .map((user) => (
            <div>
              <TimeSheetComponent
                month={month}
                uid={user.id as string}
                userData={user as users}
                vacationsData={vacationsData.filter((vacation) => vacation.employeeUID == user.id)}
                allocationsData={allocationsData.filter((allocation) => allocation.uid == user.id)}
                delaysData={delaysData.filter((delay) => delay.userUID == user.id)}
                shiftsData={shiftsData.filter((shift) => shift.uid == user.id)}
                activeDelay={activeDelay}
              />

              <div style={{ pageBreakAfter: "always" }} />
            </div>
          ))}
        <br />
      </div>
    </div>
  );
}
